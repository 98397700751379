import React, { useState, useEffect, useContext } from "react";
import { Box, Heading, Text, Layer, Image, Button, DataTable, TextInput } from "grommet";
import { Link, useHistory } from "react-router-dom";
import { Search } from "grommet-icons";
import firebase from "firebase/app";
import UserContext from "../context/UserContext";
import ClaimStore from "../components/ClaimStore";
import axios from "axios";
import { isNumber } from "underscore";

const Stores = () => {
	const history = useHistory();

	const { authUser } = useContext(UserContext);

	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);

	const [claimModal, toogleClaimModal] = useState(false);

	useEffect(() => {
		firebase
			.firestore()
			.collection("stores")
			.where("owner", "==", authUser.owner)
			.get()
			.then((querySnapshot) => {
				const res = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				setData(res);
				setFiltered(res);
			});
	}, []);

	const fillData = async () => {
		firebase
			.firestore()
			.collection("stores")
			.where("district", "==", "Centro")
			.orderBy("fullAddress")
			.startAt(1422)
			.limit(200)
			.get()
			.then(async (querySnapshot) => {
				var arr = [];
				var i = 0;
				for await (var doc of querySnapshot.docs) {
					const d = await getCoordinates(doc.data().fullAddress + doc.data().name + " Madrid", doc.id, doc.data(), doc.ref, i).then((res) => res);
					i++;
					arr.push(d);
				}
				console.log("LAS RESPUESTAS FINALES", arr);
				// querySnapshot.docs.map((doc,i) => {
				// 	// console.log(doc.data())

				// });
			});
	};

	const getCoordinates = async (address, id, data, ref, i) => {
		const d = await axios
			.post("https://europe-west1-retailhub-6602d.cloudfunctions.net/locationData", {
			// .post("http://localhost:5001/retailhub-6602d/europe-west1/locationData", {
				method: "POST",
				body: JSON.stringify({ address: address, index: i }),
			})
			.then(async (response) => {
				const info = response.data;
				// console.log("Success:", info);
				const ad = info?.coordinates;
				const addressData = ad?.address_components;
				const number = addressData?.find((el) => el.types.includes("street_number"))?.long_name.replace("Nº", "");
				const street = addressData?.find((el) => el.types.includes("route"))?.long_name ? addressData?.find((el) => el.types.includes("route"))?.long_name : data.street;
				const city = addressData?.find((el) => el.types.includes("locality"))?.long_name;
				const postalCode = addressData?.find((el) => el.types.includes("postal_code"))?.long_name;
				const advancedInfo = info.details?.result;
				const links = data.links;
				if (advancedInfo) {
					links[0] = advancedInfo?.website ? advancedInfo?.website : "";
					links[6] = advancedInfo?.formatted_phone_number ? advancedInfo?.formatted_phone_number : "";
				}

				const variables = {
					...(advancedInfo && {
						links,
						placeId: advancedInfo.place_id,
						phone: advancedInfo?.formatted_phone_number ? advancedInfo?.formatted_phone_number : data.phone,
						name: advancedInfo?.name ? advancedInfo?.name : data.name,
						schedule: advancedInfo?.opening_hours?.weekday_text ? advancedInfo?.opening_hours?.weekday_text : data.schedule,
					}),
					...(info.photos && { images: info.photos }),
					fullAddress: ad.formatted_address,
					number: isNumber(number) ? parseInt(number) : data.number,
					street,
					city,
					postalCode,
				};

				const d = await ref
					.update(variables)
					.then((res) => {
						console.log("All Good ", advancedInfo?.name, " POCESO N ", i);
						return ("All Good ", advancedInfo?.name, " POCESO N ", i);
					})
					.catch(() => {
						console.log("SE RECIBIO INFORMACION PERO NO SE PUDO ACTUALIZAR, ALGO MAL ", variables, "PROCESO ", i);
						return ("SE RECIBIO INFORMACION PERO NO SE PUDO ACTUALIZAR, ALGO MAL ", variables, "PROCESO ", i);
					});

				return d;
			})
			.catch((error) => {
				console.log("NO SE HA ENCONTRADO INFORMACION DE ", data.name);
				// throw error;
				console.log("PROCESO N ", i);
				// setLoading(false)

				return ("NO SE HA ENCONTRADO INFORMACION DE ", data.name);
			});
		return d;
	};

	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			{/* SECCION ULTIMAS PROMO */}

			<Box gap="medium" width="100%" background="white" pad="medium" elevation="medium" round="10px">
				<Box direction="row">
					<Box flex={true}>
						<Heading margin="0px" level="3" color="#170747">
							Mis Establecimientos
						</Heading>
					</Box>
					<Box direction="row" gap="small" align="center">
						<Button
							onClick={() => toogleClaimModal(true)}
							style={{
								color: "white",
								fontWeight: "600",
								textAlign: "center",
								paddingLeft: 15,
								paddingRight: 15,
								height: 35,
								borderRadius: 5,
								alignItems: "center",
								justifyContent: "center",
							}}
							primary
							color="secondary"
						>
							Solicitar Titularidad
						</Button>
						<Button
							onClick={() => history.push("/newstore")}
							style={{
								color: "white",
								fontWeight: "600",
								textAlign: "center",
								paddingLeft: 15,
								paddingRight: 15,
								height: 35,
								borderRadius: 5,
								alignItems: "center",
								justifyContent: "center",
							}}
							primary
							color="brand"
						>
							Nuevo Establecimiento
						</Button>
						<Button
							onClick={fillData}
							style={{
								color: "white",
								fontWeight: "600",
								textAlign: "center",
								paddingLeft: 15,
								paddingRight: 15,
								height: 35,
								borderRadius: 5,
								alignItems: "center",
								justifyContent: "center",
							}}
							primary
							color="brand"
						>
							Rellenar Datos
						</Button>
					</Box>
				</Box>

				<Box height="2px" round="small" background="gainsboro" width="100%" />

				{/* SEARCH BOX */}
				<Box direction="row" gap="small">
					<TextInput
						icon={<Search />}
						placeholder="Buscar Establecimientos..."
						onChange={(ev) => setFiltered(data.filter((el) => el.name.toLocaleLowerCase().includes(ev.target.value.toLocaleLowerCase())))}
					/>
					{/* <Button style={{ border: "2px solid #170747", color: "#170747", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, borderRadius: 5, alignItems: "center", justifyContent: "center" }} color="#170747">
						Filtrar
					</Button>
					<Button style={{ border: "2px solid #170747", color: "#170747", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, borderRadius: 5, alignItems: "center", justifyContent: "center" }} color="#170747">
						Ordenar
					</Button> */}
				</Box>

				<Box margin={{ top: "10px" }}>
					<DataTable
						onClickRow={({ datum }) => {
							!datum._tags.includes("pending") && history.push("/editstore/" + datum.id);
						}}
						columns={[
							{
								property: "image",
								header: "",
								render: (datum) => (
									<Box overflow="hidden" round="small" width="75px" height="80px" pad={{ vertical: "xsmall" }}>
										<Image
											fill
											fit="cover"
											src={
												datum.images && datum.images[0]
													? datum.images[0]
													: "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-product-6_large.png?format=jpg&quality=90&v=1530129477"
											}
										/>
									</Box>
								),
							},
							{
								property: "name",
								header: <Text>Nombre del Establecimiento</Text>,
								sortable: true,
								render: (datum) => (
									<Text style={{ fontWeight: 600 }} color="secondary">
										{datum.name}
									</Text>
								),
							},
							{
								property: "address",
								header: "Dirección",
								render: (datum) => datum.fullAddress,
							},
							{
								property: "status",
								header: "Estado",
								render: (datum) => (
									<Box pad={{ vertical: "xsmall" }}>
										{datum._tags.includes("pending") ? (
											<Button color={"orange"} style={{ textAlign: "center", paddingTop: 5, paddingBottom: 5, color: "white" }} primary>
												Pendiente de Validación
											</Button>
										) : (
											<Button
												color={datum.status === "ACTIVE" ? "brand" : datum.status === "CLOSED" ? "status-critical" : "secondary"}
												style={{ textAlign: "center", paddingTop: 5, paddingBottom: 5 }}
												primary
											>
												{datum.status === "ACTIVE" ? "Abierto" : datum.status === "HOLIDAYS" ? "Vacaciones" : "Cerrado"}
											</Button>
										)}
									</Box>
								),
							},
						]}
						data={filtered}
					/>
				</Box>
			</Box>

			{claimModal && (
				<ClaimStore
					refreshCallback={(ev) => {
						toogleClaimModal(ev);
						setTimeout(() => {
							window.location.reload();
						}, 500);
					}}
					modalCallback={(ev) => toogleClaimModal(ev)}
				/>
			)}
		</Box>
	);
};

export default Stores;
