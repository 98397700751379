import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useField } from "formik";

const TextBox = ({label, onEditorChange, ...props}) => {
	const [field, meta, helpers] = useField(props);
	return (
		<>
			<label htmlFor={field.name}>{label}</label>
			<Editor
				initialValue={field.value || "<p/>"}
				init={{
					height: 250,
					width: "100%",
					menubar: false,
					branding: false,
					menubar: "file edit view insert format tools table tc help",
					plugins: ["advlist autolink lists link charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime table paste code help wordcount"],
					toolbar: "undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help",
				}}
                {...field}

				onEditorChange={(ev) => helpers.setValue(ev)}
				apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
			/>
		</>
	);
};

export default TextBox;
