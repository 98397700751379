import React from 'react'
import styled from 'styled-components'

const BasicButton = ({ children, ...rest }) => {
    return (
        <Button {...rest}>{ children }</Button>
    )
}

const Button = styled.div`
    background-color: #165781;
    border-radius: 8px;
    padding: 0.5em 1em;
    color: white;
    transition: all 300ms;
    cursor: pointer;
    text-align: center;
    &:hover{
        opacity: 0.8;
    }
`

export default BasicButton