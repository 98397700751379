import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput, DataTable } from "grommet";
import { Edit, Trash, Add, Lock, FormViewHide, View, Close, Upload, Search, Mail } from "grommet-icons";
import axios from "axios";

import { withRouter } from "react-router";

import firebase from "firebase/app";
import { Link } from "react-router-dom";

const UserList = ({ location, history }) => {
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [contentID, setContent] = useState("");
	const [changePassModal, setChangePassModal] = useState({ status: false, userID: "" });
	const [changePassError, setChangePassError] = useState({ status: false, message: "" });
	const [formPass, setFormPass] = useState({ password: "", repeatpassword: "" });
	const [reveal, setReveal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [deleting, setDeleting] = useState(false);
	const [sending, setSending] = useState(false);
	const [sendMailModal, toogleSendMailModal] = useState(false);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const subs = firebase
			.firestore()
			.collection("users")
			.onSnapshot(async (res) => {
				Promise.all(res.docs.map(async (user) => ({ ...user.data(), id: user.id, ref: user.ref }))).then((users) => {
					setData(users);
					setFiltered(users);
					setLoading(false);
					console.log(users);
				});
			});

		return () => subs();
	}, []);

	const deleteUser = (uid) => {
		setDeleting(true);
		axios
			.post("https://europe-west1-apd-formacion.cloudfunctions.net/deleteUser", {
				method: "POST",
				body: JSON.stringify({ uid }),
			})
			.then((res) => {
				console.log(res);
				toogleDeleteModal(!deleteModal);
				setDeleting(false);
			})
			.catch((res) => {
				console.log(res);
				toogleDeleteModal(!deleteModal);
				setDeleting(false);
			});
	};

	const updateUserPass = () => {};

	const changeUserPassword = () => {
		if (formPass.password !== formPass.repeatpassword) {
			setChangePassError({ status: true, message: "Las contraseñas no coinciden" });
		} else {
			const variables = {
				userID: changePassModal.userID,
				password: formPass.password,
			};
			updateUserPass({ variables })
				.then((data) => {
					console.log(data);
					setChangePassError({ status: false, message: "" });
					setChangePassModal({ status: false, userID: null });
				})
				.catch((err) => {
					console.log(err);
					setChangePassError({ ...changePassError, message: err.message });
				});
		}
	};

	const sendEmail = async () => {
		setSending(true)
		// const p = data.filter((el) => el.email === "sgonzalez@cocem.es");
		const p = data.find((el) => el.id === contentID);

		axios
			.post("https://europe-west1-retailhub-6602d.cloudfunctions.net/sendMail", {
				method: "POST",
				body: JSON.stringify({ email: p.email, name: p.name }),
			})
			.then((res) => {
				alert(`El email a ${p.name} a la dirección ${p.email} se ha enviado correctamente`)
				setSending(false)
				toogleSendMailModal(false)
				return res.data;
			})
			.catch((res) => {
				alert(`Ha ocurrido un error al enviar el email a ${p.name} a la dirección ${p.email}`)
				setSending(false)
				toogleSendMailModal(false)
				return res.data;
			});
		// console.log(p);
		// const r = await Promise.all(
		// 	p.map(async (el) => {
		// 		return axios
		// 			.post("http://localhost:5001/retailhub-6602d/europe-west1/sendMail", {
		// 				method: "POST",
		// 				body: JSON.stringify({ email: el.email, name: el.name }),
		// 			})
		// 			.then((res) => {
		// 				return res.data;
		// 			})
		// 			.catch((res) => {
		// 				return res.data;
		// 			});
		// 	})
		// ).then((res) => console.log(res));
	};

	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			{/* SECCION ULTIMAS PROMO */}

			<Box gap="medium" width="100%" background="white" pad="medium" elevation="medium" round="10px">
				<Box direction="row">
					<Box flex={true}>
						<Heading margin="0px" level="3" color="#170747">
							({data.filter((el) => el.role !== "Admin").length}) Usuarios
						</Heading>
					</Box>
					<Box direction="row" gap="small" align="center">
						<Button onClick={() => history.push("/newuser")} style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
							Nuevo Usuario
						</Button>
						{/* <Button onClick={() => sendEmail()} style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
							Enviar Mail
						</Button> */}
					</Box>
				</Box>

				<Box height="2px" round="small" background="gainsboro" width="100%" />

				{/* SEARCH BOX */}
				<Box direction="row" gap="small">
					<TextInput
						icon={<Search />}
						placeholder="Buscar Usuarios..."
						onChange={(ev) => setFiltered([...data?.filter((el) => el?.email?.toLowerCase()?.includes(ev.target.value.toLowerCase()) || el?.name.toLowerCase()?.includes(ev.target.value.toLowerCase()) || el?.store?.toLowerCase()?.includes(ev.target.value.toLowerCase()))])}
					/>
				</Box>

				<Box overflow="scroll">
					{data && (
						<DataTable
							columns={[
								// {
								// 	property: "id",
								// 	header: <Text>ID</Text>,
								// 	render: (datum) => <Text size="12px">{datum.id}</Text>,
								// },
								{
									property: "order",
									header: "ID",
									render: (datum) => <Text size="12px">{datum.id}</Text>,
								},
								{
									property: "email",
									header: "Email",
									primary: true,
									render: (datum) => <Text size="12px">{datum.email}</Text>,
								},
								{
									property: "name",
									header: <Text>Nombre</Text>,
								},
								{
									property: "lastName",
									header: <Text>Apellidos</Text>,
								},
								{
									property: "role",
									header: <Text>Rol</Text>,
								},
								{
									property: "status",
									header: "Estado",
									render: (datum) =>
										datum.blocked === true ? (
											<Button style={{ color: "white" }} hoverIndicator={false} active={false} focusIndicator={false} label="Inactivo" primary color="status-error" size="small" />
										) : (
											<Button style={{ color: "white" }} hoverIndicator={false} active={false} focusIndicator={false} primary label="Activo" color="status-ok" size="small" />
										),
								},
								{
									property: "sendmail",
									header: "",
									render: (datum) => (
										<Button
											onClick={() => {
												toogleSendMailModal(true);
												setContent(datum.id);
											}}
											color="gray"
											icon={<Mail />}
											hoverIndicator
										/>
									),
								},
								{
									property: "password",
									header: "",
									render: (datum) => <Button onClick={() => setChangePassModal({ status: true, userID: datum.id })} color="gray" icon={<Lock />} hoverIndicator />,
								},
								{
									property: "edit",
									header: "",
									size: "60px",
									render: (datum) => (
										<Link to={{ pathname: `/edituser/${datum.id}` }}>
											<Button style={{ width: 60 }} fill color="gray" icon={<Edit />} hoverIndicator />
										</Link>
									),
								},
								{
									header: "",
									property: "del",
									width: 60,
									resizable: false,
									sortable: false,
									render: (datum) => (
										<Button
											color="gray"
											hoverIndicator
											icon={<Trash />}
											onClick={() => {
												toogleDeleteModal(!deleteModal);
												setContent(datum.id);
											}}
										/>
									),
								},
							]}
							data={filtered}
						/>
					)}
				</Box>
			</Box>

			{changePassModal.status && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => setChangePassModal({ status: false, userID: null })} onEsc={() => setChangePassModal({ status: false, userID: null })}>
					<Box pad="medium" gap="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								Cambiar contraseña
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez cambiada la contraseña el usuario no podrá acceder con su contraseña anterior.</Text>
						</Box>
						<Box gap="small">
							<Box direction="row" border round="7px">
								<TextInput plain type={reveal ? "text" : "password"} value={formPass.password} onChange={(ev) => setFormPass({ ...formPass, password: ev.target.value })} placeholder="Introduzca la contraseña..." />
								<Button icon={reveal ? <View size="medium" /> : <FormViewHide size="medium" />} onClick={() => setReveal(!reveal)} />
							</Box>

							<Box direction="row" border round="7px">
								<TextInput plain type={reveal ? "text" : "password"} value={formPass.repeatpassword} onChange={(ev) => setFormPass({ ...formPass, repeatpassword: ev.target.value })} placeholder="Repita la contraseña..." />
								<Button icon={reveal ? <View size="medium" /> : <FormViewHide size="medium" />} onClick={() => setReveal(!reveal)} />
							</Box>
						</Box>

						{changePassError.status && (
							<Box align="center" pad={{ horizontal: "medium" }} direction="row" justify="between" round="small" animation={changePassError.status ? "fadeIn" : "fadeOut"} background="neutral-4">
								<Text>{changePassError.message}</Text>
								<Button icon={<Close color="white" />} onClick={() => setChangePassError({ status: false, message: "" })} />
							</Box>
						)}

						<Box direction="row" justify="between" width="100%">
							<Button label="Cancelar" onClick={() => setChangePassModal({ status: false, userID: null })} />
							<Button type="submit" label="Cambiar" color="brand" disabled={formPass.password === ""} primary onClick={() => changeUserPassword()} />
						</Box>
					</Box>
				</Layer>
			)}

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este foro?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el foro no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" width="100%" margin={{ top: "medium" }}>
							<Button label="Cancelar" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								disabled={deleting}
								label={deleting ? "Eliminando" : "Eliminar"}
								color="status-critical"
								primary
								onClick={() => {
									deleteUser(contentID);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{sendMailModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleSendMailModal(!sendMailModal)} onEsc={() => toogleSendMailModal(!sendMailModal)}>
					<Box pad="medium">
						<Heading level="4" margin="none">
							Vas a enviar un email de bienvenida a {data.find((el) => el.id === contentID).email}, estas seguro que quieres hacerlo?
						</Heading>
						<Box direction="row" justify="between" width="100%" margin={{ top: "medium" }}>
							<Button label="Cancelar" onClick={() => toogleSendMailModal(!sendMailModal)} />
							<Button
								type="submit"
								disabled={sending}
								label={sending ? "Enviando" : "Enviar"}
								color="status-critical"
								primary
								onClick={() => {
									sendEmail(contentID);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(UserList);
