import React from "react";
import { useField } from "formik";
import { CheckBox } from "grommet";

const CheckBoxBox = ({ label, ...props }) => {
	const [field, meta, helpers] = useField(props);
	return <CheckBox checked={field.value} label={label} {...field} onChange={(ev) => helpers.setValue(ev.target.checked)} />;
};

export default CheckBoxBox;
