import React, { useContext } from "react";
import { Box, Image, Select, Header as HeaderGrommet, ResponsiveContext, DropButton } from "grommet";
import { Login, Map, Logout, Group, Dashboard, Catalog, MapLocation, Document, Organization, Menu as Men, User, Globe, ContactInfo, SettingsOption, Help, Cloud, Aggregate, Article, Analytics, Refresh } from "grommet-icons";
import { Link, NavLink } from "react-router-dom";
import footerlogos from "../assets/img/footerlogos.png";
import UserContext from "../context/UserContext";

const Menu = ({ authed }) => {
	const size = useContext(ResponsiveContext);

	const { authUser } = useContext(UserContext);
	return authed ? (
		<Box width={{ min: "200px", max: "350px" }} justify="between" height="100%" border={{ side: "right", size: "5px", style: "solid", color: "brand" }} background="white" elevation="large">
			<Box pad={{ horizontal: "medium" }}>
				<Link to="/home">
					<Box width="small" height="50px" margin={{ top: "15px" }}>
						<Image src={"https://retailhub.es/static/media/logoBlack.1ab3ecbb.png"} fit="contain" />
					</Box>
				</Link>
				<Box width="100%" height="3px" background="gainsboro" style={{ opacity: 0.3 }} margin={{ top: "15px" }} round />
			</Box>
			<Box margin={{ top: "medium" }} height={{ max: "90%" }} overflow={{ vertical: "auto" }} width="100%" align="start">
				<Box flex="grow" width="100%" justify="center" height="50px" key="/home">
					<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/home" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
						<MapLocation style={{ marginRight: 5 }} color="black" />
						<span style={{ color: "black" }}>Home</span>
					</NavLink>
				</Box>
				{authUser.role !== "Association" && authUser.role !== "User" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/stores">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/stores" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<Group style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Mi comercio</span>
						</NavLink>
					</Box>
				)}
				{authUser.role !== "Association" && authUser.role !== "User" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/products">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/products" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<Globe color="black" style={{ marginRight: 5 }} />
							<span style={{ color: "black" }}>Productos</span>
						</NavLink>
					</Box>
				)}
				<Box flex="grow" width="100%" justify="center" height="50px" key="/promotions">
					<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/promotions" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
						<Organization style={{ marginRight: 5 }} color="black" />
						<span style={{ color: "black" }}>Promociones</span>
					</NavLink>
				</Box>
				<Box flex="grow" width="100%" justify="center" height="50px" key="/transactions">
					<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/transactions" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
						<Catalog style={{ marginRight: 5 }} color="black" />
						<span style={{ color: "black" }}>Transacciones</span>
					</NavLink>
				</Box>
				<Box flex="grow" width="100%" justify="center" height="50px" key="/configuration">
					<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/configuration" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
						<Dashboard style={{ marginRight: 5 }} color="black" />
						<span style={{ color: "black" }}>Configuracion</span>
					</NavLink>
				</Box>
				<Box flex="grow" width="100%" justify="center" height="50px" key="/help">
					<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/help" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
						<Help style={{ marginRight: 5 }} color="black" />
						<span style={{ color: "black" }}>Ayuda</span>
					</NavLink>
				</Box>
				<Box flex="grow" width="100%" justify="center" height="50px" key="/club">
					<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/club" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
						<Cloud style={{ marginRight: 5 }} color="black" />
						<span style={{ color: "black" }}>Club del Comercio</span>
					</NavLink>
				</Box>
				{authUser.role === "Admin" && <Box flex="grow" width="100%" justify="center" height="50px" key="/clubform">
					<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/clubform" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
						<Cloud style={{ marginRight: 5 }} color="black" />
						<span style={{ color: "black" }}>Nueva Oferta</span>
					</NavLink>
				</Box>}
				{(authUser.role === "Association" || authUser.role === "Admin") && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/contents">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/contents" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<Article style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Microsite</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/users">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/users" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<User style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Usuarios</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/pendingusers">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/pendingusers" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<User style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Aprobación Altas Nuevas</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/pendingstores">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/pendingstores" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<User style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Aprobación Establecimientos</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/categories">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/categories" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<SettingsOption style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Categorías</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/sectors">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/sectors" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<ContactInfo style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Sectores</span>
						</NavLink>
					</Box>
				)}
				{(authUser.role === "Association" || authUser.role === "Admin") && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/routes">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/routes" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<Map style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Rutas</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/analytics">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/analytics" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<Analytics style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Analítica</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/associations">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/associations" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<Aggregate style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Asociaciones</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/pages">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/pages" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<Document style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Páginas</span>
						</NavLink>
					</Box>
				)}
				{authUser.role === "Admin" && (
					<Box flex="grow" width="100%" justify="center" height="50px" key="/pages">
						<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/massstores" activeStyle={{ backgroundColor: "rgba(0,0,0,.05)" }}>
							<Refresh style={{ marginRight: 5 }} color="black" />
							<span style={{ color: "black" }}>Registro Campo</span>
						</NavLink>
					</Box>
				)}

				<Box flex="grow" width="100%" justify="center" height="50px" key="/logout">
					<NavLink style={{ display: "flex", paddingLeft: 15, flex: 1, alignItems: "center" }} to="/logout">
						<Logout style={{ marginRight: 5 }} color="black" />
						<span style={{ color: "black" }}>Salir</span>
					</NavLink>
				</Box>
			</Box>

			<Box width="small" height="50px" pad={{ horizontal: "medium" }}>
				<Image src={footerlogos} fit="contain" />
			</Box>
		</Box>
	) : (
		<Box />
	);
};

export default Menu;
