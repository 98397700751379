import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase'

const Logout = ({ history }) => {
    useEffect(() => {
        firebase.auth().signOut().then( async resp => {
            console.log(resp)
            await localStorage.clear()
            await history.push("/")
        })
    }, [])
    return null
}

export default withRouter(Logout)