import React from "react";
import { useField } from "formik";
import { Select } from "grommet";

const SelectBox = ({ label, options = [], sendCallback = false, callback, ...props }) => {
	const [field, meta, helpers] = useField(props);
	return (
		<>
			<label htmlFor={field.name}>{label}</label>
			<Select
				{...field}
				{...props}
				value={field.value}
				options={options}
				onChange={({ value: nextValue }) => {
					sendCallback && callback(nextValue);
					helpers.setValue(nextValue);
				}}
			/>
		</>
	);
};

export default SelectBox;
