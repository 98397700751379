import React, { useState, useEffect, useReducer } from "react";
import "./App.css";

import "react-table/react-table.css";
import { ReactTableDefaults } from "react-table";
import { Grommet, Box, Layer } from "grommet";
import Loader from "react-loader-spinner";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { UserContext } from "./context";

import { Home, Logout, NotFound, Login } from "./containers";

import { PrivateRoute, Footer } from "./components";

import firebase from "firebase/app";

// eslint-disable-next-line
import Menu from "./components/Menu";
import Products from "./containers/Products";
import ProductForm from "./containers/ProductForm";
import Transactions from "./containers/Transactions";
import Promotions from "./containers/Promotions";
import PromotionForm from "./containers/PromotionForm";
import StoreForm from "./containers/StoreForm";
import NewStoreForm from "./containers/NewStoreForm";
import Stores from "./containers/Stores";
import Configuration from "./containers/Configuration";
import Help from "./containers/Help";
import Club from "./containers/Club";
import Categories from "./containers/Categories";
import Subcategories from "./containers/Subcategories";
import Sectors from "./containers/Sectors";
import Activities from "./containers/Activities";
import Article from "./containers/Article";
import HelpArticleForm from "./containers/HelpArticleForm";
import UserList from "./containers/UserList";
import UserForm from "./containers/UserForm";
import Contents from "./containers/Contents";
import ContentForm from "./containers/ContentForm";
import Pages from "./containers/Pages";
import PageForm from "./containers/PageForm";
import Associations from "./containers/Associations";
import AssociationForm from "./containers/AssociationForm";
import PendingStores from "./containers/PendingStores";
import Analytics from "./containers/Analytics";
import Routes from "./containers/Routes";
import RouteForm from "./containers/RouteForm";
import PendingUsers from "./containers/PendingUsers";
import ClubPromotion from "./containers/ClubPromotion";
import MassStoreHandler from "./containers/MassStoreHandler";

const firebaseConfig = {
	apiKey: "AIzaSyA7522FCbabm1HahH84jN8_GKK6uw4lJmE",
	authDomain: "retailhub-6602d.firebaseapp.com",
	projectId: "retailhub-6602d",
	storageBucket: "retailhub-6602d.appspot.com",
	messagingSenderId: "3759132075",
	appId: "1:3759132075:web:d8186d741b3ae579ec96ee",
	measurementId: "G-QB35SPW44R",
};

firebase.initializeApp(firebaseConfig);

const theme = {
	global: {
		colors: {
			brand: "#FFC148",
			secondary: "#170747",
			focus: "gainsboro",
		},
		font: {
			family: "Poppins",
			size: "14px",
			color: "#170747",
			height: "20px",
		},
	},
	button: {
		border: {
			radius: "25px",
			color: "white",
		},
		padding: {
			vertical: "10px",
		},
	},
};

Object.assign(ReactTableDefaults, {
	previousText: "Anterior",
	nextText: "Siguiente",
	loadingText: "Cargando...",
	noDataText: "No hay datos",
	pageText: "Página",
	ofText: "de",
	rowsText: "filas",
	pageJumpText: "ir a página",
	rowsSelectorText: "filas por página",
});

function App() {
	const [showMenu, setShowMenu] = useState(true);

	const [authUser, setAuthUser] = useState(null);
	const [authed, setAuthed] = useState(null);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const listener = firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				firebase
					.firestore()
					.collection("users")
					.doc(user.uid)
					.get()
					.then(async (res) => {
						setAuthUser(user ? { ...user, ref: res.ref, ...res.data(), id: res.id } : null);
						setLoading(false);
						setAuthed(true);
					})
					.catch((err) => console.log(err));
			} else {
				setLoading(false);
				setAuthed(false);
			}
		});

		return () => listener();
	}, []);

	return (
		<Grommet theme={theme} full background="#F5F6F9">
			<Router>
				<UserContext.Provider value={{ authUser: authUser, setAuthUser: setAuthUser }}>
					<Box height="100%" direction="row">
						<Menu authed={authed} />
						<Box fill overflow="auto">
							{loading ? (
								<Layer animate={false} plain full={true}>
									<Box fill background="rgba(0,0,0,.6)" align="center" justify="center">
										<Loader type="Puff" color="white" height={100} width={100} />
									</Box>
								</Layer>
							) : (
								<Switch>
									<PrivateRoute authed={authUser} path="/analytics" component={Analytics} />
									<PrivateRoute authed={authUser} path="/associations" component={Associations} />
									<PrivateRoute authed={authUser} path="/newassociation/" component={AssociationForm} />
									<PrivateRoute authed={authUser} path="/editassociation/:id" component={AssociationForm} />
									<PrivateRoute authed={authUser} path="/editpage/:id" component={PageForm} />
									<PrivateRoute authed={authUser} path="/newpage" component={PageForm} />
									<PrivateRoute authed={authUser} path="/pages" component={Pages} />
									<PrivateRoute authed={authUser} path="/editcontent/:id" component={ContentForm} />
									<PrivateRoute authed={authUser} path="/newcontent" component={ContentForm} />
									<PrivateRoute authed={authUser} path="/contents" component={Contents} />
									<PrivateRoute authed={authUser} path="/activities/:id" component={Activities} />
									<PrivateRoute authed={authUser} path="/sectors" component={Sectors} />
									<PrivateRoute authed={authUser} path="/subcategories/:id" component={Subcategories} />
									<PrivateRoute authed={authUser} path="/categories" component={Categories} />
									<PrivateRoute authed={authUser} path="/clubpromotion/:id" component={ClubPromotion} />
									<PrivateRoute authed={authUser} path="/club" component={Club} />
									<PrivateRoute authed={authUser} path="/edithelparticle/:id" component={HelpArticleForm} />
									<PrivateRoute authed={authUser} path="/article/:id" component={Article} />
									<PrivateRoute authed={authUser} path="/help" component={Help} />
									<PrivateRoute authed={authUser} path="/configuration" component={Configuration} />
									<PrivateRoute authed={authUser} path="/pendingstores" component={PendingStores} />
									<PrivateRoute authed={authUser} path="/pendingusers" component={PendingUsers} />
									<PrivateRoute authed={authUser} path="/stores" component={Stores} />
									<PrivateRoute authed={authUser} path="/massstores" component={MassStoreHandler} />
									<PrivateRoute authed={authUser} path="/newstore/" component={StoreForm} />
									<PrivateRoute authed={authUser} path="/editstore/:id" component={StoreForm} />
									<PrivateRoute authed={authUser} path="/newstoremad" component={NewStoreForm} />
									<PrivateRoute authed={authUser} path="/editstoremad/:id" component={NewStoreForm} />
									<PrivateRoute authed={authUser} path="/routes" component={Routes} />
									<PrivateRoute authed={authUser} path="/newroute/" component={RouteForm} />
									<PrivateRoute authed={authUser} path="/editroute/:id" component={RouteForm} />
									<PrivateRoute authed={authUser} path="/products" component={Products} />
									<PrivateRoute authed={authUser} path="/newproduct/" component={ProductForm} />
									<PrivateRoute authed={authUser} path="/editproduct/:id" component={ProductForm} />
									<PrivateRoute authed={authUser} path="/transactions" component={Transactions} />
									<PrivateRoute authed={authUser} path="/promotions" component={Promotions} />
									<PrivateRoute authed={authUser} path="/newpromotion/" component={PromotionForm} />
									<PrivateRoute authed={authUser} path="/editpromotion/:id" component={PromotionForm} />
									<PrivateRoute authed={authUser} path="/edituser/:id" component={UserForm} />
									<PrivateRoute authed={authUser} path="/newuser" component={UserForm} />
									<PrivateRoute authed={authUser} path="/users" component={UserList} />
									<PrivateRoute authed={authUser} path="/home" component={Home} />
									<Route exact path="/logout" component={Logout} />
									<Route exact path="/login" component={Login} />
									<Route exact path="/" component={Login} />
									<Route path="" component={NotFound} />
								</Switch>
							)}
						</Box>
					</Box>
					{/* <Footer showMenu={showMenu} email={authUser && authUser.professionalEmail} /> */}
				</UserContext.Provider>
			</Router>
		</Grommet>
	);
}

export default App;
