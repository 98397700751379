import React from 'react'
import { Box } from 'grommet'

const NotFound = () => {
    return (
        <Box height="100%">
            <h1>404</h1>
        </Box>
    )
}

export default NotFound