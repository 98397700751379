import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput } from "grommet";
import { CaretPrevious, Trash } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import SelectBox from "../components/SelectBox";
import ImageBox from "../components/ImageBox";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";

const HelpArticleForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();

	const [initialData, setInitialData] = useState({
		name: "",
		content: "",
		video: "",
		status: true,
		groups: [""]
	});
	const [coordinates, setCoordinates] = useState(null);
	const [urlModal, setUrlModal] = useState(false);
	const [url, setUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [loadingCoordinates, setLoadingCoordinates] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);

	useEffect(() => {
		id && fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("helparticles")
			.doc(id)
			.get()
			.then((res) => {
				setInitialData(res.data());
				setCoordinates(res.data()._geoloc);
			});
	};

	const ArticleSchema = Yup.object().shape({
		name: Yup.string().min(3, "El nombre del artículo debe tener más de 2 caracteres").max(50, "El nombre del establecimiento no puede superar los 50 caracteres").required("Pon un nombre del establecimiento"),
		// links: Yup.string().url("La URL introducida no es válida. Ejemplo: https://urlalestablecimiento.com"),
	});

	const deleteArticle = async () => {
		setLoading(true);
		firebase
			.firestore()
			.collection("helparticles")
			.doc(id)
			.delete()
			.then((res) => {
				setLoading(false);
				alert("El articulo se ha eliminado con éxito");
				history.push("/help");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error al eliminar el articulo ", JSON.stringify(err));
			});
	};
	const submitForm = async (values) => {
		setLoading(true);

		id
			? firebase
					.firestore()
					.collection("helparticles")
					.doc(id)
					.update({ ...values })
					.then((res) => {
						setLoading(false);
						alert("El ariculo se ha actualizado con éxito");
						history.push("/help");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					})
			: firebase
					.firestore()
					.collection("helparticles")
					.add({ ...values })
					.then((res) => {
						setLoading(false);
						alert("El articulo se ha agregado con éxito");
						history.push("/help");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					});
	};

	const onKeyDown = (keyEvent) => {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
		}
	};

	return (
		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={ArticleSchema} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched }) => (
				<Form onKeyDown={onKeyDown}>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/help")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									{id ? initialData.name : "Nuevo Artículo"}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{id && !loading && (
									<Button
										onClick={() => deleteArticle()}
										type="submit"
										style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
										primary
										color="status-critical">
										Eliminar
									</Button>
								)}
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="75%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box>
										<label htmlFor="name">Título del Articulo</label>
										<Field className="basicInput" id="name" name="name" placeholder="Título..." />
										{errors.name && touched.name ? (
											<Text size="16px" color="status-critical">
												{errors.name}
											</Text>
										) : null}
									</Box>
									<TextBox name="content" label="Contenido" />
									<Box>
										<label htmlFor="video">Video</label>
										<Field className="basicInput" id="video" name="video" placeholder="Video..." />
									</Box>
								</Box>

								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Imagen destacada
											</Heading>
										</Box>
									</Box>

									<ImageBox name="image" />
								</Box>
							</Box>

							<Box gap="medium" width="25%">
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Inactivo"
										name="status"
										label="Estado"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ value: true, label: "Publicado" },
											{ value: false, label: "Borrador" },
										]}
									/>
								</Box>

								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Grupos de Usuarios"
										name="groups"
										label="Grupos"
										labelKey="label"
										multiple
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ value: "Commerce", label: "Comerciante" },
											{ value: "Association", label: "Asociación" },
										]}
									/>
									<Text size="small">Este campo representa a los grupos de usuarios que será visible este contenido</Text>
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default HelpArticleForm;

const CustomMarker = ({ lat, lng }) => <div lat={lat} lng={lng} style={{ backgroundImage: "url(https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png)", backgroundSize: "cover", backgroundRepeat: "no-repeat", height: 40, width: 40, marginTop: -40, marginLeft: -20 }} />;
