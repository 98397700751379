import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput, DataTable } from "grommet";
import { Edit, Trash, Add, Lock, FormViewHide, View, Close, Upload, Search, Mail } from "grommet-icons";
import axios from "axios";

import { withRouter } from "react-router";

import firebase from "firebase/app";
import { Link } from "react-router-dom";

const Contents = ({ location, history }) => {
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [changePassModal, setChangePassModal] = useState({ status: false, userID: "" });
	const [changePassError, setChangePassError] = useState({ status: false, message: "" });
	const [formPass, setFormPass] = useState({ password: "", repeatpassword: "" });
	const [reveal, setReveal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [deleting, setDeleting] = useState(false);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const subs = firebase
			.firestore()
			.collection("contents")
			.onSnapshot(async (res) => {
				Promise.all(res.docs.map(async (user) => ({ ...user.data(), id: user.id, ref: user.ref }))).then((users) => {
					setData(users);
					setFiltered(users);
					setLoading(false);
					console.log(users);
				});
			});

		return () => subs();
	}, []);

	const deleteContent = () => {
		firebase
			.firestore()
			.collection("contents")
			.doc(contentIDToDelete)
			.delete()
			.then((res) => {
				toogleDeleteModal(false);
			})
			.catch((err) => console.log(err));
	};

	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			{/* SECCION ULTIMAS PROMO */}

			<Box gap="medium" width="100%" background="white" pad="medium" elevation="medium" round="10px">
				<Box direction="row">
					<Box flex={true}>
						<Heading margin="0px" level="3" color="#170747">
							Mis Contenidos
						</Heading>
					</Box>
					<Box direction="row" gap="small" align="center">
						<Button onClick={() => history.push("/newcontent")} style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
							Nuevo Contenido
						</Button>
						{/* <Button onClick={() => sendEmail()} style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
							Enviar Mail
						</Button> */}
					</Box>
				</Box>

				<Box height="2px" round="small" background="gainsboro" width="100%" />

				{/* SEARCH BOX */}
				<Box direction="row" gap="small">
					<TextInput icon={<Search />} placeholder="Buscar Contenidos..." onChange={(ev) => setFiltered([...data?.filter((el) => el?.title?.toLowerCase()?.includes(ev.target.value.toLowerCase()))])} />
				</Box>

				<Box>
					{data && (
						<DataTable
							columns={[
								{
									property: "image",
									header: "",
									size:"90px",
									render: (datum) => (
										<Box overflow="hidden" round="small" width="75px" height="80px" pad={{ vertical: "xsmall" }}>
											<Image fill fit="cover" src={datum.image ? datum.image : "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-product-6_large.png?format=jpg&quality=90&v=1530129477"} />
										</Box>
									),
								},
								{
									property: "title",
									header: "Título",
									primary: true,
									size: "large",
									render: (datum) => <Text >{datum.title}</Text>,
								},
								{
									property: "type",
									header: <Text>Tipo</Text>,
								},
								{
									header: "Estado",
									property: "status",
									render: (datum) =>
										!datum.status ? (
											<Button style={{ color: "white" }} hoverIndicator={false} active={false} focusIndicator={false} label="Borrador" primary color="status-error" size="small" />
										) : (
											<Button style={{ color: "white" }} hoverIndicator={false} active={false} focusIndicator={false} primary label="Publicado" color="status-ok" size="small" />
										),
								},
								{
									header: "",
									property: "edit",
									size: "60px",
									render: (datum) => (
										<Link to={{ pathname: `/editcontent/${datum.id}` }}>
											<Button style={{ width: 60 }} fill color="gray" icon={<Edit />} hoverIndicator />
										</Link>
									),
								},
								{
									header: "",
									width: 60,
									resizable: false,
									sortable: false,
									render: (datum) => (
										<Button
											color="gray"
											hoverIndicator
											icon={<Trash />}
											onClick={() => {
												toogleDeleteModal(!deleteModal);
												setContentIDToDelete(datum.id);
											}}
										/>
									),
								},
							]}
							data={filtered}
						/>
					)}
				</Box>
			</Box>

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este Contenido?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el contenido no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" width="100%" margin={{ top: "medium" }}>
							<Button label="Cancelar" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								disabled={deleting}
								label={deleting ? "Eliminando" : "Eliminar"}
								color="status-critical"
								primary
								onClick={() => {
									deleteContent(contentIDToDelete);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(Contents);
