import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput, Collapsible } from "grommet";
import { CaretPrevious, Trash } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import SelectBox from "../components/SelectBox";
import CheckBoxBox from "../components/CheckBoxBox";
import Dropzone from "react-dropzone";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";

const ProductsForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();
	const [initialData, setInitialData] = useState({
		name: "",
		description: "",
		link: "",
		price: "",
		discountedPrice: "",
		variants: [],
		images: [],
		stores: [],
		storesDetails: [],
		status: true,
		primaryCategory: "",
		secondaryCategory: "",
		brand: "",
	});

	const [stores, setStores] = useState([]);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);

	const [urlModal, setUrlModal] = useState(false);
	const [url, setUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);

	useEffect(() => {
		fetchStores();
		fetchCategories();
		id && fetchData();
	}, []);

	useEffect(() => {
		initialData.primaryCategory && fetchSubCategories(initialData.primaryCategory);
	}, [initialData]);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("products")
			.doc(id)
			.get()
			.then((res) => {
				setInitialData(res.data());
			});
	};

	const fetchStores = async () => {
		firebase
			.firestore()
			.collection("stores")
			.where("owner", "==", authUser.owner)
			.get()
			.then((querySnapshot) => {
				const s = querySnapshot.docs.map((d) => ({ value: d.id, label: d.data().name, _geoloc: d.data()._geoloc }));
				setStores(s);
			});
	};

	const fetchCategories = async () => {
		firebase
			.firestore()
			.collection("platform")
			.doc("categories")
			.get()
			.then((doc) => {
				setCategories(doc.data().data);
			})
			.catch((err) => alert("Ha ocurrido un error al cargar las categorías, vuelva mas tarde"));
	};
	const fetchSubCategories = async (cat) => {
		const c = categories.find((el) => el.name === cat);
		c?.id &&
			firebase
				.firestore()
				.collection("platform")
				.doc("categories")
				.collection("subcategories")
				.doc(c.id)
				.get()
				.then((doc) => {
					setSubCategories(doc.data().data);
				});
	};

	const ProductSchema = Yup.object().shape({
		name: Yup.string().min(3, "El nombre del producto debe tener más de 2 caracteres").required("Pon un nombre del producto"),
		link: Yup.string().url("La URL introducida no es válida. Ejemplo: https://urlalproducto.com"),
	});

	const imageDropped = (file, values) =>
		new Promise((resolve, reject) => {
			setLoadingImage(true);
			console.log(file);
			const fileRef = firebase.storage().ref().child(id ? `products/${id}/${new Date().valueOf()}${file[0].name}` : `products/${new Date().valueOf()}${file[0].name}`);
			fileRef
				.put(file[0])
				.then((res) => {
					firebase
						.storage()
						.ref(res.ref.fullPath)
						.getDownloadURL()
						.then((res) => resolve(res))
						.catch((err) => reject(err));
				})
				.catch((err) => reject(err));
		});

	const deleteProduct = async () => {
		setLoading(true);
		firebase
			.firestore()
			.collection("products")
			.doc(id)
			.delete()
			.then((res) => {
				setLoading(false);
				alert("El producto se ha eliminado con éxito");
				history.push("/products");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error al eliminar el producto ", JSON.stringify(err));
			});
	};
	
	const submitForm = async (values) => {
		setLoading(true);
		const geo = values.stores.map((s) => stores.find((el) => el.value === s)?._geoloc);
		const storesDetails = values.stores.map((s) => {
			const name = stores.find((el) => el.value === s).label;
			const id = stores.find((el) => el.value === s).value;
			return { name, id };
		});
		id
			? firebase
					.firestore()
					.collection("products")
					.doc(id)
					.update({ ...values, _geoloc: geo, storesDetails, owner: authUser.owner })
					.then((res) => {
						setLoading(false);
						alert("El producto se ha actualizado con éxito");
						history.push("/products");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					})
			: firebase
					.firestore()
					.collection("products")
					.add({ ...values, _geoloc: geo, storesDetails, owner: authUser.owner })
					.then((res) => {
						setLoading(false);
						alert("El producto se ha agregado con éxito");
						history.push("/products");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					});
	};

	return (
		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={ProductSchema} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched }) => (
				<Form>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/products")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									{id ? initialData.name : "Nuevo Producto"}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{id && !loading && (
									<Button
										onClick={() => deleteProduct()}
										type="submit"
										style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
										primary
										color="status-critical">
										Eliminar
									</Button>
								)}
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="75%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box>
										<label htmlFor="name">Nombre del Producto</label>
										<Field className="basicInput" id="name" name="name" placeholder="Nombre del Producto..." />
										{errors.name && touched.name ? (
											<Text size="16px" color="status-critical">
												{errors.name}
											</Text>
										) : null}
									</Box>
									<TextBox name="description" label="Descripción" />
									<Box>
										<label htmlFor="link">Enlace al Producto</label>
										<Field className="basicInput" id="link" name="link" placeholder="Enlace al Producto..." />
										{errors.link && touched.link ? (
											<Text size="16px" color="status-critical">
												{errors.link}
											</Text>
										) : null}
									</Box>
								</Box>
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row" gap="medium">
										<Box flex>
											<label htmlFor="price">Precio del Producto</label>
											<Field type="number" className="basicInput" id="price" name="price" placeholder="Precio del Producto..." />
										</Box>
										<Box flex>
											<label htmlFor="discountedPrice">Precio en Promoción</label>
											<Field type="number" className="basicInput" id="discountedPrice" name="discountedPrice" placeholder="Precio en Promoción..." />
										</Box>
									</Box>

									<Box margin={{ top: "10px" }} width="100%" height="2px" round background="gainsboro" />

									<FieldArray name="variants">
										{({ insert, remove, push }) => (
											<Box gap="medium">
												<Box gap="small">
													<Text size="16px" color="secondary">
														Variantes del Producto
													</Text>
													<Box height={{ min: "150px" }} gap="small">
														{values?.variants?.length > 0 ? (
															values.variants.map((variant, index) => (
																<Box key={index} gap="small" direction="row">
																	<Field className="basicInput" style={{ flex: 2 }} type="text" name={`variants.${index}.name`} placeholder="Nombre de la Variante..." />
																	<Field className="basicInput" style={{ flex: 1 }} type="text" name={`variants.${index}.option`} placeholder="Opción de la Variante..." />
																	<Field className="basicInput" style={{ flex: 1 }} type="number" name={`variants.${index}.price`} placeholder="Precio de la Variante..." />
																	<Button onClick={() => remove(index)} icon={<Trash />} />
																</Box>
															))
														) : (
															<Box height="150px" background="#f8f8f8" round="small" align="center" justify="center">
																<Text>Sin Variantes</Text>
															</Box>
														)}
													</Box>
												</Box>
												<Box width="100%" height="2px" round background="gainsboro" />
												<Box align="start">
													<Box border={{ size: "2px", color: "secondary" }} round="5px">
														<Button
															onClick={() => push({ name: "", color: "" })}
															style={{ fontWeight: "600", borderWidth: 2, borderColor: "#170747", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
															color="#170747">
															Añadir Variante
														</Button>
													</Box>
												</Box>
											</Box>
										)}
									</FieldArray>
								</Box>

								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Imágenes
											</Heading>
										</Box>
										<Box onClick={() => setUrlModal(true)}>
											<Text size="small" color="secondary" style={{ textDecoration: "underline" }}>
												Añadir desde una url
											</Text>
										</Box>
									</Box>

									<FieldArray name="images">
										{({ insert, remove, push }) => (
											<>
												<Box gap="small">
													<Dropzone
														accept="image/*"
														disabled={values.images.length >= 10}
														maxFiles={10}
														maxSize={2242880}
														onDropAccepted={(ev) =>
															imageDropped(ev).then((res) => {
																push(res);
																setLoadingImage(false);
																console.log(res);
															})
														}>
														{({ acceptedFiles, getRootProps, getInputProps }) => (
															<Box round="5px" border={{ size: "2px", style: "dashed", color: "gainsboro" }} align="center" justify="center" height={{ min: "150px" }} {...getRootProps()}>
																<input {...getInputProps()} />
																<Text size="small" color="secondary">
																	Haz Click o Arrastra imagenes aquí. (2MB Max por imagen)
																</Text>
															</Box>
														)}
													</Dropzone>
													<Box direction="row" gap="small">
														{values?.images?.length > 0 &&
															values.images.map((image, index) => (
																<Stack key={index} anchor="top-right">
																	<Box round="5px" overflow="hidden" background="gainsboro" width="100px" height="100px">
																		<Image fit="cover" src={image} />
																	</Box>
																	<Button style={{ marginTop: 0, borderRadius: 5, padding: 7, marginRight: 0 }} primary color="status-critical" onClick={() => remove(index)} icon={<Trash color="white" size="15px" />} />
																</Stack>
															))}
													</Box>
												</Box>
												{urlModal && (
													<Layer>
														<Box pad="medium" gap="medium" width="medium">
															<Text>Añadir Imagen desde una URL</Text>
															<Box>
																<TextInput value={url} onChange={(ev) => setUrl(ev.target.value)} placeholder="URL de la imagen" />
																{!url.includes("http") && (
																	<Text style={{ fontSize: "10px" }} color="status-critical">
																		La url tiene que tener este formato: https://urldelaimagen.com
																	</Text>
																)}
															</Box>
															<Box direction="row" justify="between">
																<Button
																	onClick={() => {
																		setUrlModal(false);
																		setUrl("");
																	}}
																	style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
																	primary
																	color="gainsboro">
																	Cancelar
																</Button>
																<Button
																	disabled={url === "" && ProductSchema.isValid({ link: url }).then((res) => console.log(res))}
																	onClick={() => {
																		push(url);
																		setUrlModal(false);
																		setUrl("");
																	}}
																	style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
																	primary
																	color="brand">
																	Añadir
																</Button>
															</Box>
														</Box>
													</Layer>
												)}
											</>
										)}
									</FieldArray>
								</Box>
							</Box>

							<Box gap="medium" width="25%">
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Inactivo"
										name="status"
										label="Estatus"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										// sendCallback={true}
										// callback={(ev) => console.log("ESTATUS ", ev)}
										options={[
											{ value: false, label: "Inactivo" },
											{ value: true, label: "Activo" },
										]}
									/>

									<Text size="12px">El estado del producto refleja si este aparecerá en las busquedas</Text>
								</Box>
								<Box background="white" gap="small" border={{ side: "top", color: "brand", size: "7px" }} pad="medium" elevation="medium" round="10px">
									<Heading margin="0px" level="4" color="#170747">
										Organización
									</Heading>
									<Text size="12px">Selecciona las categorīas de tu producto para que aparezca en las busquedas</Text>

									<SelectBox placeholder="Categoría Principal" name="primaryCategory" label="Categoría Principal" labelKey="name" valueKey={{ key: "name", reduce: true }} sendCallback callback={(ev) => fetchSubCategories(ev)} options={categories} />
									<Collapsible open={values.primaryCategory !== ""}>
										<SelectBox placeholder="Categoría Secundaria" name="secondaryCategory" label="Categoría Secundaria" options={subCategories} />
									</Collapsible>
									<Box>
										<label htmlFor="discountedPrice">Marca</label>
										<Field className="basicInput" id="brand" name="brand" placeholder="Marca..." />
									</Box>
								</Box>

								<Box background="white" gap="small" border={{ side: "top", color: "brand", size: "7px" }} pad="medium" elevation="medium" round="10px">
									<Heading margin="0px" level="4" color="#170747">
										Disponibilidad
									</Heading>
									<Text size="12px">Selecciona en que establecimientos estará disponible este producto</Text>
									{/* <FieldArray name="stores">
									<CheckBoxBox label=""/>
									</FieldArray> */}
									<SelectBox multiple closeOnChange={false} placeholder="Selecciona Establecimiento..." name="stores" label="Establecimientos" labelKey="label" valueKey={{ key: "value", reduce: true }} options={stores} />
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default ProductsForm;
