import React, { useState, useEffect } from "react";
import { Box, Text, Heading, Image, Button } from "grommet";

import firebase from "firebase/app";
import { useHistory, useParams } from "react-router-dom";
import { CaretPrevious, Globe, Instagram, Phone } from "grommet-icons";

import renderHTML from "react-render-html";

import ReactPlayer from "react-player";

const ClubPromotion = () => {
	const [data, setData] = useState([]);
	const { id } = useParams();
	const history = useHistory();

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("clubpromotions")
			.doc(id)
			.get()
			.then((doc) => {
				setData({ ...doc.data() });
			});
	};

	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			<Box pad="medium" round="small" background="white" elevation="medium" gap="medium">
				<Box direction="row" gap="small" align="center">
					<Button onClick={() => history.push("/club")} icon={<CaretPrevious />} primary color="white" />
					<Text>Volver</Text>
				</Box>
				<Box width="100%" align="center" justify="center" height="200px" round="small" overflow="hidden" style={{ position: "relative" }}>
					<Image fill fit="cover" src={data.image !== "" && data.image ? data.image : "https://oij.org/wp-content/uploads/2016/05/placeholder.png"} style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} />
					<Box style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} background="rgba(0,0,0,.5)" />
					<Heading level="2" margin="0px" style={{ maxWidth: "100%", zIndex: 3, color: "white" }}>
						{data.title}
					</Heading>
				</Box>

				<Box>
					<Heading level="3" margin={{ bottom: "20px", top: "20px" }}>Sobre {data.company}</Heading>

					<Text>{data.description && renderHTML(data.about)}</Text>

					<Box direction="row" gap="small" margin={{ top: "20px" }}>
						{data.web && <Button style={{ borderRadius: 7 }} color="#f2f2f2" label="Web" hoverIndicator icon={<Globe />} href={data.web} target="_blank" onPress={() => window.open(data.web, "_blank")} />}
						{data.instagram && <Button style={{ borderRadius: 7 }} color="#f2f2f2" label="Instagram" hoverIndicator icon={<Instagram />} href={data.instagram} target="_blank" onPress={() => window.open(data.instagram, "_blank")} />}
						{data.phone && <Button style={{ borderRadius: 7 }} color="#f2f2f2" label={data.phone} hoverIndicator icon={<Phone />} href={data.phone} target="_blank" onPress={() => window.open(data.phone, "_blank")} />}
					</Box>
				</Box>
				<Box align="start">
					<Heading level="3" margin={{ bottom: "20px", top: "20px" }}>Descripción de la oferta</Heading>
					<Text>{data.description && renderHTML(data.description)}</Text>
					{data?.offer && <Box margin={{ top: "25px" }}>
						<Text size="small">{data?.offer?.prev}</Text>
						<Box justify="start" align="baseline" gap="5px" background="#f2f2f2" round="small" pad="medium" direction="row">
							<Heading level="2" margin="0px" style={{ textDecoration: "line-through", opacity: .5 }} color="gray">{data?.offer?.regularPrice}</Heading>
							<Heading level="2" margin="0px" color="status-ok">{data?.offer?.price}</Heading>
							<Text size="small">{data?.offer?.frecuency}</Text>
						</Box>
					</Box>}
				</Box>

				<Box align="start">
					<Heading level="3" margin={{ bottom: "20px", top: "20px" }}>Contratación</Heading>
					<Text style={{ marginBottom: 20 }}>Para mas información o contratar el servicio el siguiente enlace te redireccionará al sitio web de {data.company}</Text>
					{data.embeded ?
						<iframe style={{border:"0px"}} src={data.embeded} width="100%" height={500}/>
						:
						<Button href={data.form} target="_blank" onPress={() => window.open(data.form, "_blank")} style={{ borderRadius: 10 }} label="Formulario de Contacto" primary />}
				</Box>

				{data.video && <Box align="start" gap="medium">
					<Heading level="3" margin={{ bottom: "0px", top: "20px" }}>Video</Heading>
					{data.video && data.video !== "" && (
						<Box align="center">
							<ReactPlayer controls={true} url={data.video} />
						</Box>
					)}
				</Box>}
			</Box>
		</Box>
	);
};

export default ClubPromotion;
