import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput } from "grommet";
import { CaretPrevious, Trash, View } from "grommet-icons";
import { Formik, Field, Form, FieldArray, FastField } from "formik";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";

const Subcategories = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();

	const [initialData, setInitialData] = useState({
		name: "",
		data: [],
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("platform")
			.doc("categories")
			.collection("subcategories")
			.doc(id)
			.get()
			.then((res) => {
				setInitialData(res.data());
			});
	};

	const submitForm = async (values) => {
		setLoading(true);

		await firebase
			.firestore()
			.collection("platform")
			.doc("categories")
			.collection("subcategories")
			.doc(id)
			.update(values)
			.then((res) => {
				setLoading(false);
				alert("Las subcategorías se han actualizado con éxito");
				history.push("/categories");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error ", JSON.stringify(err));
			});
	};

	return (
		<Formik enableReinitialize={true} initialValues={initialData} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched }) => (
				<Form>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/categories")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									Subcategorías de {initialData.name}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="100%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<label htmlFor="name">Nombre de la Categoría</label>
									<Field className="basicInput" id="name" name="name" placeholder="Nombre de la Categoría..." />
									<FieldArray name="data">
										{({ insert, remove, push }) => (
											<Box gap="medium">
												<Box gap="small">
													<Text size="16px" color="secondary">
														Subcategorías de {initialData.name}
													</Text>
													<Box height={{ min: "150px" }} pad={{left:"15px"}} gap="small">
														{values?.data?.length > 0 ? (
															values.data.map((categorie, index) => (
																<Box key={index} gap="small" direction="row">
																	<FastField className="basicInput" style={{ flex: 2 }} type="text" name={`data.${index}`} placeholder="Nombre de la Subcategoría..." />
																	<Button onClick={() => remove(index)} icon={<Trash />} />
																</Box>
															))
														) : (
															<Box height="150px" background="#f8f8f8" round="small" align="center" justify="center">
																<Text>Sin Subcategorías</Text>
															</Box>
														)}
													</Box>
												</Box>
												<Box width="100%" height="2px" round background="gainsboro" />
												<Box align="start">
													<Box border={{ size: "2px", color: "secondary" }} round="5px">
														<Button
															onClick={() => push("")}
															style={{ fontWeight: "600", borderWidth: 2, borderColor: "#170747", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
															color="#170747">
															Añadir Subcategoría
														</Button>
													</Box>
												</Box>
											</Box>
										)}
									</FieldArray>
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default Subcategories;
