import React from 'react'
import styled from 'styled-components'
import { FormAdd } from 'grommet-icons'

const CircleAdd = ({ onClick, ...rest }) => {
    return (
        <ChachiCircle>
            <FormAdd onClick={onClick} {...rest}/>
        </ChachiCircle>
    )
}

const ChachiCircle = styled.div`
    display: flex;
    justify-content: center;
    transition: all 300ms;
    svg {
        fill: white;
        stroke: white;
        background: #165781;
        border-radius: 16px;
        padding: 4px;
    }
    svg:hover {
        opacity: 0.8;
    }
`

export default CircleAdd