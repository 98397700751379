import React, { useState } from "react";
import { Image, Box, Text, Button } from "grommet";
import { useField } from "formik";
import Dropzone from "react-dropzone";
import { Trash } from "grommet-icons";
import firebase from 'firebase/app'

const ImageBox = ({ label, onEditorChange, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const [loadingImage, setLoadingImage] = useState(false);

	const imageDropped = (file, values) =>
		new Promise((resolve, reject) => {
			setLoadingImage(true);
			console.log(file);
			const fileRef = firebase.storage().ref().child(`stores/images/${new Date().valueOf()}${file[0].name}`);
			fileRef
				.put(file[0])
				.then((res) => {
					firebase
						.storage()
						.ref(res.ref.fullPath)
						.getDownloadURL()
						.then((res) => resolve(res))
						.catch((err) => reject(err));
				})
				.catch((err) => reject(err));
		});

	return (
		<Dropzone
			accept="image/*"
			maxFiles={1}
			maxSize={2242880}
			onDropAccepted={(ev) =>
				imageDropped(ev).then((res) => {
					helpers.setValue(res);
					setLoadingImage(false);
				})
			}>
			{({ getRootProps, getInputProps }) => (
				<Box style={{ position: "relative" }} round="5px" border={{ size: "2px", style: "dashed", color: "gainsboro" }} align="center" justify="center" height={{ min: "150px" }} {...getRootProps()}>
					<input {...getInputProps()} />
					{field.value ? (
						<Image src={field.value} fill fit="cover" />
					) : (
						<Text size="small" color="secondary">
							{loadingImage ? "Cargando Imagen" : "Haz Click o Arrastra la imagen principal. (2MB Max por imagen"}
						</Text>
					)}
					{field.value && <Button style={{ borderRadius: 5, position: "absolute", top: 0, right: 0 }} primary color="status-critical" icon={<Trash />} onClick={() => helpers.setValue("")} />}
				</Box>
			)}
		</Dropzone>
	);
};

export default ImageBox;
