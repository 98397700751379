import React, { useState, useEffect, useContext } from "react";
import { Box, Keyboard, TextInput, Text, MaskedInput, Image, Button } from "grommet";
import { View, Hide } from "grommet-icons";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context";
import BG from "../assets/img/bglogin.jpg";
import Loader from "react-loader-spinner";

const Login = () => {
	const history = useHistory();
	const { authUser, setAuthUser } = useContext(UserContext);

	const [loading, setLoading] = useState(false);
	const [reveal, setReveal] = useState(false);
	const [errorLogin, setErrorLogin] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	var user = firebase.auth().currentUser;

	useEffect(() => {
		if (user) {
			// User is signed in.
			history.push("/home");
		} else {
			// No user is signed in.
		}
		if (authUser) {
		}
	}, []);

	const login = () => {
		// console.log(email)
		// console.log(password)
		setLoading(true);
		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((re) => {
				firebase
					.firestore()
					.collection("users")
					.doc(re.user.uid)
					.get()
					.then(async (doc) => {
						if (doc.exists) {
							setLoading(false);
							await setAuthUser({ ...re.user, ref: doc.ref, ...doc.data() });
							await history.push("/home");
						} else {
							// doc.data() will be undefined in this case
							setLoading(false);
							setErrorLogin(true);
							console.log("No such document!");
						}
					});
			})
			.catch((error) => {
				// Handle Errors here.
				// var errorCode = error.code;
				// var errorMessage = error.message;
				// console.log(errorMessage)
				setLoading(false);
				setErrorLogin(true);
			});
	};

	// const storeInfo = (doc) => {
	//     return Promise.resolve(localStorage.setItem("user", JSON.stringify(doc))).then(() => {
	//         history.replace('/')
	//     });
	// }

	return (
		<Box flex="grow" pad="medium" justify="center" align="center" background={{ color: "#FFC148", image: `url(${BG})`, repeat: "no-repeat", position: "bottom", size: "contain" }}>
			<Box gap="medium" style={{ backgroundColor: "white" }} align="center" pad="medium" round="medium">
				<Box width="300px">
					<Image fit="contain" src={require("../assets/img/logoblack.png")} />
				</Box>
				{errorLogin && (
					<Box animation="fadeIn" align="center" background="status-error" round="small" pad="small">
						<Text color="white">Usuario o contraseña incorrectos.</Text>
					</Box>
				)}

				<Keyboard onEnter={login}>
					<Box width="medium" gap="medium">
						<MaskedInput
							mask={[
								{
									regexp: /^[\w\-_.]+$/,
									placeholder: "ejemplo",
								},
								{ fixed: "@" },
								{
									regexp: /^[\w\-_.]+$/,
									placeholder: "direccion",
								},
								{ fixed: "." },
								{
									regexp: /^[\w]+$/,
									placeholder: "com",
								},
							]}
							name="email"
							size="medium"
							type="email"
							value={email}
							onChange={(event) => setEmail(event.target.value)}
						/>
						<Box width="medium" direction="row" align="center" round="4px" border>
							<TextInput plain value={password} onChange={(ev) => setPassword(ev.target.value)} placeholder="Contraseña" type={reveal ? "text" : "password"} style={{ fontSize: 18 }} />
							<Button icon={reveal ? <View size="medium" /> : <Hide size="medium" />} onClick={() => setReveal(!reveal)} />
						</Box>
						{loading ? (
							<Box width="100%" height="50px" align="center" justify="center">
								<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
							</Box>
						) : (
							<Button label="Acceder" onClick={login} primary />
						)}
					</Box>
				</Keyboard>
			</Box>
		</Box>
	);
};

export default Login;
