import React, { useEffect, useState, useContext } from "react";
import { Box, Heading, Text, Image, Button, DataTable } from "grommet";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import UserContext from "../context/UserContext";

const Home = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const [data, setData] = useState({
		products: 0,
		promotions: 0,
		transactions: 0,
	});

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const products = await firebase
			.firestore()
			.collection("products")
			.where("owner", "==", authUser.owner)
			.get()
			.then((querySnapshot) => ({ total: querySnapshot.docs.length, docs: querySnapshot.docs.map((doc) => doc.data()) }));
		const promotions = await firebase
			.firestore()
			.collection("promotions")
			.where("owner", "==", authUser.owner)
			.get()
			.then((querySnapshot) => ({ total: querySnapshot.docs.length, docs: querySnapshot.docs.map((doc) => doc.data()) }));
		const transactions = await firebase
			.firestore()
			.collection("transactions")
			.where("owner", "==", authUser.owner)
			.get()
			.then((querySnapshot) => ({ total: querySnapshot.docs.length, docs: querySnapshot.docs.map((doc) => doc.data()) }));
		console.log(promotions);
		await setData({
			products: products.total,
			promotions: promotions.total,
			transactions: transactions.total,
			topPromotion: promotions.docs[0],
		});
	};
	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			{/* SECCION RESUMEN */}
			<Box direction="row" height="250px" gap="medium">
				<Box width="50%" background="white" pad="medium" elevation="medium" round="10px">
					<Heading margin="0px" level="4" color="#170747">
						Bienvenido {authUser.name}, este es tu resumen
					</Heading>
					<Box direction="row" style={{ marginTop: 10 }} gap="small">
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{data.products}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Productos
								</Text>
							</Box>
							<Button onClick={() => history.push("/products")} style={{ color: "white", textAlign: "center", height: 40, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
								Ver Mas
							</Button>
						</Box>
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{data.promotions}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Promociones
								</Text>
							</Box>
							<Button onClick={() => history.push("/promotions")} style={{ color: "white", textAlign: "center", height: 40, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
								Ver Mas
							</Button>
						</Box>
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{data.transactions}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Transacciones
								</Text>
							</Box>
							<Button onClick={() => history.push("/transactions")} style={{ color: "white", textAlign: "center", height: 40, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
								Ver Mas
							</Button>
						</Box>
					</Box>
				</Box>
				<Box width="50%" background="white" pad="medium" elevation="medium" round="10px">
					<Heading margin="0px" level="4" color="#170747">
						Última promoción creada
					</Heading>
					{data.topPromotion ? (
						<>
							<Box background={{ color: "#170747", image: `url(${data.topPromotion.image})`, opacity: "weak" }} pad={{ vertical: "small", horizontal: "medium" }} style={{ position: "relative" }} round="small" direction="column" margin={{ top: "10px" }} height="50%">
								<Box style={{ zIndex: 0, borderRadius: 10, position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} background="rgba(0,0,0,.3)" />
								<Heading margin="0px" style={{ fontSize: 50, color: "white", zIndex: 1 }} color="#170747">
									{data.topPromotion.type === "RUFFLE" ? "Sorteo" : data.topPromotion.type === "2X1" ? "2 por 1" : "Descuento"}
								</Heading>
								<Text style={{ fontSize: 14, color: "white", zIndex: 1 }} color="#170747">
									{data.topPromotion.name}
								</Text>
							</Box>

							<Box margin={{ top: "15px" }} align="center" direction="row">
								<Box flex={true}>
									<Text style={{ fontSize: 14 }} color="#170747">
										Este Cupón ha sido canjeado <br />
										<strong>0 veces en los últimos 30 dias</strong>
									</Text>
								</Box>
								<Box>
									<Button onClick={() => history.push("/promotions")} style={{ color: "white", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Detalles
									</Button>
								</Box>
							</Box>
						</>
					) : (
						<Box flex align="center" justify="center">
							<Heading level="3" style={{ opacity: 0.3 }}>
								No tienes promociones activas
							</Heading>
						</Box>
					)}
				</Box>
			</Box>

			{/* SECCION ULTIMAS PROMO */}

			<Box width="100%" background="white" pad="medium" elevation="medium" round="10px">
				<Box direction="row">
					<Box flex={true}>
						<Heading margin="0px" level="4" color="#170747">
							Últimas Promociones Descargadas
						</Heading>
					</Box>
					<Box>
						<Button onClick={() => history.push("/promotions")} style={{ color: "white", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
							Ver Promociones
						</Button>
					</Box>
				</Box>
				<Box margin={{ top: "10px" }}>
					<DataTable
						columns={[
							{
								property: "name",
								header: <Text>Nombre de la Promoción</Text>,
							},
							{
								property: "percent",
								header: "Fecha",
								render: (datum) => <Box pad={{ vertical: "xsmall" }}></Box>,
							},
							{
								property: "user",
								header: "Cliente",
								primary: true,
								render: (datum) => <Box pad={{ vertical: "xsmall" }}></Box>,
							},
							{
								property: "status",
								header: "Estado",
								render: (datum) => (
									<Box pad={{ vertical: "xsmall" }}>
										<Button color="#F1F1F1" style={{ textAlign: "center", paddingTop: 5, paddingBottom: 5 }} primary>
											Descargada
										</Button>
									</Box>
								),
							},
						]}
						data={[]}
					/>
					<Box height="300px" width="100%" align="center" justify="center" round="small" background="#f3f3f3" margin={{top:"2px"}}>
						<Text>No se han descargado promociones recientemente</Text>
					</Box>
				</Box>
			</Box>

			{/* AYUDA */}

			<Box direction="row" pad="small" align="center" round="9px" background="brand">
				<Box flex={true}>
					<Text margin="0px" style={{ color: "white", fontSize: 16, fontWeight: "600" }} color="#170747">
						Si tienes cualquier duda o pregunta sobre la plataforma consulta la seccion de ayuda
					</Text>
				</Box>
				<Box>
					<Button onClick={() => history.push("/help")} style={{ color: "#170747", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="white">
						Ver Tutoriales
					</Button>
				</Box>
			</Box>

			{/* SECCION NOTICIAS */}

			<Box width="100%" background="white" pad="medium" elevation="medium" round="10px">
				<Heading margin="0px" level="4" color="#170747">
					Noticias y Novedades
				</Heading>

				<Box direction="row" margin={{ top: "15px" }} gap="medium">
					<Box direction="row" gap="small" flex={true}>
						<Box flex="grow" width="small" height="small" round="small" overflow="hidden">
							<Image fill fit="cover" src={"https://images.pexels.com/photos/374894/pexels-photo-374894.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} />
						</Box>
						<Box gap="small" height="100%" justify="center">
							<Text size="16px" weight="bold">
								Seguimos en El Barrio gracias a ti
							</Text>
							<Text size="12px">El 08 de septiembre de 2020, presentamos la campaña “Seguimos en El Barrio gracias a ti” de Mastercard al Ayuntamiento de Madrid para apoyar al comercio de proximidad...</Text>
							<Box align="start">
								<Button style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
									Leer Mas
								</Button>
							</Box>
						</Box>
					</Box>
					<Box direction="row" gap="small" flex={true}>
						<Box flex="grow" width="small" height="small" round="small" overflow="hidden">
							<Image fill fit="cover" src={"https://images.pexels.com/photos/5778479/pexels-photo-5778479.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} />
						</Box>
						<Box gap="small" height="100%" justify="center">
							<Text size="16px" weight="bold">
								Seguimos en El Barrio gracias a ti
							</Text>
							<Text size="12px">El 08 de septiembre de 2020, presentamos la campaña “Seguimos en El Barrio gracias a ti” de Mastercard al Ayuntamiento de Madrid para apoyar al comercio de proximidad...</Text>
							<Box align="start">
								<Button style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
									Leer Mas
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Home;
