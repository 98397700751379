import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput, Collapsible } from "grommet";
import { CaretPrevious, Trash } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import ImageBox from "../components/ImageBox";
import SelectBox from "../components/SelectBox";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";
import CheckBoxBox from "../components/CheckBoxBox";
import DateBox from "../components/DateBox";

const PageForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();
	const [initialData, setInitialData] = useState({
		title: "",
		content: "",
		draft: true,
		image: "",
	});

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		id && fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("pages")
			.doc(id)
			.get()
			.then((res) => {
				setInitialData(res.data());
			});
	};

	const FormSchema = Yup.object().shape({
		title: Yup.string().min(3, "El nombre de la página debe tener más de 2 caracteres").max(50, "El nombre de la página no puede superar los 50 caracteres").required("Pon un nombre a la página"),
	});

	const deletePage = async () => {
		setLoading(true);
		firebase
			.firestore()
			.collection("pages")
			.doc(id)
			.delete()
			.then((res) => {
				setLoading(false);
				alert("La página se ha eliminado con éxito");
				history.push("/pages");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error al eliminar la página ", JSON.stringify(err));
			});
	};

	const submitForm = async (values) => {
		setLoading(true);

		id
			? firebase
					.firestore()
					.collection("pages")
					.doc(id)
					.update({ ...values, owner: authUser.owner })
					.then((res) => {
						setLoading(false);
						alert("La página se ha actualizado con éxito");
						history.push("/pages");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					})
			: firebase
					.firestore()
					.collection("pages")
					.add({ ...values, owner: authUser.owner })
					.then((res) => {
						setLoading(false);
						alert("La página se ha agregado con éxito");
						history.push("/pages");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					});
	};

	return (
		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={FormSchema} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched }) => (
				<Form>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/pages")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									{id ? initialData.title : "Nueva página"}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{id && !loading && (
									<Button onClick={() => deletePage()} type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="status-critical">
										Eliminar
									</Button>
								)}
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="75%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box>
										<label htmlFor="title">Nombre de la página</label>
										<Field className="basicInput" id="title" name="title" placeholder="Nombre de la página..." />
										{errors.name && touched.name ? (
											<Text size="16px" color="status-critical">
												{errors.title}
											</Text>
										) : null}
									</Box>
									<TextBox name="content" label="Contenido" />
								</Box>

								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Imagen destacada
											</Heading>
										</Box>
									</Box>

									<ImageBox name="image" />
								</Box>
							</Box>

							<Box gap="medium" width="25%">
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Inactivo"
										name="draft"
										label="Estado"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ value: false, label: "Públicada" },
											{ value: true, label: "Borrador" },
										]}
									/>
									<Text size="12px">El estado de la página permitira a los usuarios verla en tu negocio</Text>
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default PageForm;
