import React from 'react'
import { withRouter } from 'react-router-dom'
import { Layer, Box, Text, Button } from 'grommet'

const NotFoundModal = ({ visible, history }) => {
    const toHome = () => history.push("/dashboard")
    if(visible){
        return (
            <Layer
                modal={true}
                onClickOutside={() => toHome()}
                onEsc={() => toHome()}
                style={{ padding: "2em"}}
            >
                <Box>
                    <Text style={{ marginBottom: "1em" }}>Esta página no está accesible o no existe.</Text>
                    <Button primary label="Volver al Inicio" onClick={() => toHome()} />
                </Box>
            </Layer>
        ) 
    } 
    else {
        return <></>
    }
}

export default withRouter(NotFoundModal)