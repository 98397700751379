import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from "react-router-dom";


const PrivateRoute = ({ component: Component, ...rest }) => {

    if (rest.authed === null) {
        return null
    }
    if (rest.authed) {
        // User is signed in.
        return <Route {...rest} render={props => <Component {...props} />} />
    } else {
        // No user is signed in.
        return <Redirect push to="/login" />
    }
}

export default PrivateRoute