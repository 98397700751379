import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput } from "grommet";
import { CaretPrevious, Trash } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import SelectBox from "../components/SelectBox";
import Dropzone from "react-dropzone";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";
import CheckBoxBox from "../components/CheckBoxBox";

const ProductsForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const [initialData, setInitialData] = useState({
		name: "",
		lastName: "",
		contactEmail: "",
		association: "",
		commercialNotifications: false,
		associationNotifications: false,
		retailHubNotifications: false,
	});

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("users")
			.doc(authUser.id)
			.get()
			.then((res) => {
				setInitialData({ ...initialData, ...res.data() });
			});
	};

	const UserSchema = Yup.object().shape({
		name: Yup.string().min(3, "El nombre debe tener más de 2 caracteres").max(50, "El nombre no puede superar los 50 caracteres").required("Escribe tu nombre"),
		lastName: Yup.string().min(3, "Los apellidos deben tener más de 2 caracteres").max(50, "Los apellidos no pueden superar los 50 caracteres").required("Escribe tus apellidos"),
		email: Yup.string().email("El email introducido no es válida. Ejemplo: nombre@email.com"),
	});

	const submitForm = async (values) => {
		setLoading(true);
		firebase
			.firestore()
			.collection("users")
			.doc(authUser.id)
			.update({ ...values })
			.then((res) => {
				setLoading(false);
				alert("Tus datos se ha actualizado con éxito");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error ", JSON.stringify(err));
			});
	};

	return (
		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={UserSchema} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched }) => (
				<Form>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Heading margin="none" level="4">
									Ajustes de mi Cuenta
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="100%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box>
										<label htmlFor="contactEmail">Email de contacto</label>
										<Field className="basicInput" id="contactEmail" name="contactEmail" placeholder="Email..." />
										{errors.contactEmail && touched.contactEmail ? (
											<Text size="16px" color="status-critical">
												{errors.contactEmail}
											</Text>
										) : null}
									</Box>
									<Box direction="row" gap="medium">
										<Box flex>
											<label htmlFor="name">Nombre</label>
											<Field className="basicInput" id="name" name="name" placeholder="Nombre..." />
											{errors.name && touched.name ? (
												<Text size="16px" color="status-critical">
													{errors.name}
												</Text>
											) : null}
										</Box>
										<Box flex>
											<label htmlFor="lastName">Apellidos</label>
											<Field className="basicInput" id="lastName" name="lastName" placeholder="Apellidos..." />
											{errors.lastName && touched.lastName ? (
												<Text size="16px" color="status-critical">
													{errors.lastName}
												</Text>
											) : null}
										</Box>
									</Box>

									<SelectBox
										placeholder="Selecciona tu asociación"
										name="association"
										label="Asociación"
										labelKey="label"
										valueKey={{ key: "value" }}
										options={[
											{ label: "COCEM", value: "1" },
											{ label: "Retail Hub", value: "2" },
										]}
									/>
									<Box margin={{ top: "small" }}>
										<CheckBoxBox name="commercialNotifications" label="Deseo recibir comunicaciones de ofertas y promociones" />
										<CheckBoxBox name="associationNotifications" label="Deseo recibir noticias de mi asociación" />
										<CheckBoxBox name="retailHubNotifications" label="Deseo recibir noticias de Retail Hub" />
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default ProductsForm;
