import React, { useState, useEffect } from "react";
import { Box, Text, Heading, Image, Button } from "grommet";

import firebase from "firebase/app";
import { useHistory, useParams } from "react-router-dom";
import { CaretPrevious } from "grommet-icons";

import renderHTML from "react-render-html";

import ReactPlayer from "react-player";

const Article = () => {
	const [data, setData] = useState([]);
	const { id } = useParams();
	const history = useHistory();

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("helparticles")
			.doc(id)
			.get()
			.then((doc) => {
				setData({ ...doc.data() });
			});
	};

	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			<Box pad="medium" round="small" background="white" elevation="medium" gap="medium">
				<Box direction="row" gap="small" align="center">
					<Button onClick={() => history.push("/help")} icon={<CaretPrevious />} primary color="white" />
					<Text>Volver</Text>
				</Box>
				<Box width="100%" height="200px">
					<Image fill fit="cover" src={data.image !== "" && data.image ? data.image : "https://oij.org/wp-content/uploads/2016/05/placeholder.png"} />
				</Box>
				<Heading level="2" margin="0px">
					{data.name}
				</Heading>
				<Text>{data.content && renderHTML(data.content)}</Text>
				{data.video && data.video !== "" && (
					<Box width="100%" align="center">
						<ReactPlayer controls={true} url={data.video} />
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default Article;
