import React from 'react';
import { Box, Heading, Text } from 'grommet';

const cos = Math.cos;
const sin = Math.sin;
const π = Math.PI;

const f_matrix_times = (([[a, b], [c, d]], [x, y]) => [a * x + b * y, c * x + d * y]);
const f_rotate_matrix = ((x) => [[cos(x), -sin(x)], [sin(x), cos(x)]]);
const f_vec_add = (([a1, a2], [b1, b2]) => [a1 + b1, a2 + b2]);

const createCurve = (percentaje, width, thickness) => {
    var [t1, Δ] = [180 * 0.0174533, percentaje]
    var [cx, cy] = [width / 2, width / 2]
    var [rx, ry] = [width / 2 - thickness, width / 2 - thickness]
    var φ = 0
    Δ = Δ % (2 * π);
    const rotMatrix = f_rotate_matrix(φ);
    const [sX, sY] = (f_vec_add(f_matrix_times(rotMatrix, [rx * cos(t1), ry * sin(t1)]), [cx, cy]));
    const [eX, eY] = (f_vec_add(f_matrix_times(rotMatrix, [rx * cos(t1 + Δ), ry * sin(t1 + Δ)]), [cx, cy]));
    const fA = ((Δ > π) ? 1 : 0);
    const fS = ((Δ > 0) ? 1 : 0);

    return "M " + sX + " " + sY + " A " + [rx, ry, φ / (2 * π) * 360, fA, fS, eX, eY].join(" ")
}


const Velocimenter = ({ width = 200, height = 100, thickness = 7, title = "", round = true, value = 0, max = 100, min = 0, objective = 0, background = "gainsboro", isPercentaje = false }) => {


    var percentajeRadians = ((value - min) * 100) / (max - min) / 100 * 180 * 0.0174533
    var percentajeObjetiveRadians = ((objective - min) * 100) / (max - min) / 100 * 180 * 0.0174533

    var curveResult = createCurve(percentajeRadians, width, thickness)
    var curveObjective = createCurve(percentajeObjetiveRadians, width, thickness)

    var length = (2 * Math.PI * (width / 2) - (thickness * 2)) * (180 / 360)

    var percentaje = 100 - ((((value - min) * 100) / (max - min) / 100) * 100)
    var percentajeObjetive = 100 - ((((objective - min) * 100) / (max - min) / 100) * 100)


    var colind = "red";

    if (percentaje > 66.6) { colind = "red" }
    else if (percentaje > 33.3) { colind = "orange" }
    else { colind = "green" }


    return (
        <Box width={width + "px"}
            // height={width / 2 + "px"} 
            align="center" justify="center">
            {/* <Stack fill anchor="bottom"> */}
            <Box width={width + "px"} height={width / 2 + "px"}>
                <svg fill="transparent" viewBox={`0 0 ${width} ${width / 2 + thickness / 2}`} strokeWidth={thickness} strokeLinecap={round ? "round" : "butt"} >

                    <defs>
                        <linearGradient id="green">
                            <stop offset="0" stopColor="#429321" stopOpacity="1" />
                            <stop offset="1" stopColor="#B4EC51" stopOpacity="1" />
                        </linearGradient>
                        <linearGradient id="orange">
                            <stop offset="0" stopColor="#F76B1C" stopOpacity="1" />
                            <stop offset="1" stopColor="#FAD961" stopOpacity="1" />
                        </linearGradient>
                        <linearGradient id="red">
                            <stop offset="0" stopColor="#9F041B" stopOpacity="1" />
                            <stop offset="1" stopColor="#F5515F" stopOpacity="1" />
                        </linearGradient>
                    </defs>

                    <path className="animatedTransition" d={`M ${thickness} ${width / 2} A ${width / 2 - thickness}  ${width / 2 - thickness} 0 0 1 ${width - thickness} ${width / 2}`} stroke={background} />
                    <path className="animatedTransition" className="animatedTransition" d={curveObjective} stroke="black" strokeDasharray={length} strokeDashoffset={percentajeObjetive * length / 100} stroke={`url(#${colind})`} style={{ opacity: .2 }} />
                    <path className="animatedTransition" className="animatedTransition" d={curveResult} stroke="red" strokeDasharray={length} strokeDashoffset={percentaje * length / 100} stroke={`url(#${colind})`} />

                    {/* <path className="animatedTransition" d={`M ${thickness} ${width / 2} A ${width / 2 - thickness}  ${width / 2 - thickness} 0 0 1 ${width - thickness} ${width / 2}`} stroke={background} /> */}
                    {/* <path className="animatedTransition" d={`M ${thickness} ${width / 2} A ${width / 2 - thickness}  ${width / 2 - thickness} 0 0 1 ${width - thickness} ${width / 2}`} strokeDasharray={length} strokeDashoffset={percentajeObjetive * length / 100} stroke="blue" /> */}
                    {/* <path className="animatedTransition" d={`M ${thickness} ${width / 2} A ${width / 2 - thickness}  ${width / 2 - thickness} 0 0 1 ${width - thickness} ${width / 2}`} strokeDasharray={length} strokeDashoffset={percentaje * length / 100} stroke="red" /> */}

                </svg>
            </Box>
            {/* </Stack> */}
            <Box fill align="center" justify="center" margin={{top:"-20px"}}>
                <Heading textAlign="center" level="2" margin="none">{value}{isPercentaje ? <Text>%</Text> : null}</Heading>
                <Heading textAlign="center" level="4" color="gray" margin="none">{title}</Heading>
            </Box>
        </Box>
    )
}

export default Velocimenter