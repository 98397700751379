import React, { useState, useEffect, useContext } from "react";
import { Box, Heading, Text, Layer, Image, Button, DataTable, TextInput } from "grommet";
import { Link, useHistory } from "react-router-dom";
import { Search } from "grommet-icons";
import firebase from "firebase/app";
import UserContext from "../context/UserContext";

const Associations = () => {
	const history = useHistory();

	const { authUser } = useContext(UserContext);

	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);

	useEffect(() => {
		firebase
			.firestore()
			.collection("associations")
			.get()
			.then((querySnapshot) => {
				const res = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				setData(res);
				setFiltered(res);
			});
	}, []);

	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			{/* SECCION ULTIMAS PROMO */}

			<Box gap="medium" width="100%" background="white" pad="medium" elevation="medium" round="10px">
				<Box direction="row">
					<Box flex={true}>
						<Heading margin="0px" level="3" color="#170747">
							Asociaciones
						</Heading>
					</Box>
					<Box direction="row" gap="small" align="center">
						<Button onClick={() => history.push("/newassociation")} style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
							Nueva Asociacion
						</Button>
					</Box>
				</Box>

				<Box height="2px" round="small" background="gainsboro" width="100%" />

				{/* SEARCH BOX */}
				<Box direction="row" gap="small">
					<TextInput icon={<Search />} placeholder="Buscar Asociaciones..." onChange={(ev) => setFiltered(data.filter((el) => el.name.toLocaleLowerCase().includes(ev.target.value.toLocaleLowerCase())))} />
					{/* <Button style={{ border: "2px solid #170747", color: "#170747", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, borderRadius: 5, alignItems: "center", justifyContent: "center" }} color="#170747">
						Filtrar
					</Button>
					<Button style={{ border: "2px solid #170747", color: "#170747", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, borderRadius: 5, alignItems: "center", justifyContent: "center" }} color="#170747">
						Ordenar
					</Button> */}
				</Box>

				<Box margin={{ top: "10px" }}>
					<DataTable
						onClickRow={({ datum }) => history.push("/editassociation/" + datum.id)}
						columns={[
							{
								property: "image",
								header: "",
								render: (datum) => (
									<Box overflow="hidden" round="small" width="75px" height="80px" pad={{ vertical: "xsmall" }}>
										<Image fill fit="cover" src={datum.images && datum.images[0] ? datum.images[0] : "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-product-6_large.png?format=jpg&quality=90&v=1530129477"} />
									</Box>
								),
							},
							{
								property: "name",
								header: <Text>Nombre de la Asociacion</Text>,
								sortable: true,
								render: (datum) => (
									<Text style={{ fontWeight: 600 }} color="secondary">
										{datum.name}
									</Text>
								),
							},
							{
								property: "associates",
								header: "Asociados",
								render: (datum) => datum.associates || 0,
							}
						]}
						data={filtered}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Associations;
