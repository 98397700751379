import React, { useState } from "react";
import { useField } from "formik";
import { TextInput, Box, DropButton, Calendar } from "grommet";
import { Schedule } from "grommet-icons";
import moment from "moment";

const DateBox = ({ label, options = [], ...props }) => {
	const [schedule, openSchedule] = useState(false);
	const [field, meta, helpers] = useField(props);

	return (
		<>
			<TextInput
				type="date"
				placeholder="Selecciona un día"
				onChange={(ev) => helpers.setValue(ev.target.valueAsDate)}
				value={field.value}
			/>
			{/* <label htmlFor={field.name}>{label}</label>
			<DropButton
				{...field}
				open={schedule}
				onClose={() => openSchedule(false)}
				onOpen={() => openSchedule(true)}
				dropContent={
					<Calendar
						animate={false}
						size="small"
						daysOfWeek={true}
						firstDayOfWeek={1}
						locale="es-ES"
						bounds={[moment().format(), moment().add("years", 2).format()]}
						date={field.value || moment().format()}
						onSelect={(ev) => {
							helpers.setValue(ev)
							openSchedule(false);
						}}
						showAdjacentDays={false}
					/>
				}>
				<Box direction="row" gap="small" align="center">
					<TextInput
						// type="date"
						placeholder="Selecciona un día"
						value={field.value ? `${new Date(field.value).toLocaleDateString()}` : ""}
					/>
					<Box pad="small">
						<Schedule />
					</Box>
				</Box>
			</DropButton> */}
		</>
	);
};

export default DateBox;
