import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, CheckBox, Collapsible, TextInput } from "grommet";
import { CaretPrevious, Trash } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import ImageBox from "../components/ImageBox";
import SelectBox from "../components/SelectBox";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";
import CheckBoxBox from "../components/CheckBoxBox";
import DateBox from "../components/DateBox";

const PromotionForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();
	const [initialData, setInitialData] = useState({
		name: "",
		description: "",
		link: "",
		products: [],
		stores: [],
		allProducts: true,
		allStores: true,
		image: "",
		initDate: "",
		endDate: "",
		status: false,
		unlimited: false,
		type: "DISCOUNTAMOUNT",
		amount: 0,
		availability: "UNLIMITED",
		units: 0,
	});

	const [loading, setLoading] = useState(false);
	const [stores, setStores] = useState([]);
	const [products, setProducts] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		id && fetchData();
		fetchStores();
		fetchProducts();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("promotions")
			.doc(id)
			.get()
			.then((res) => {
				const initDate = res.data().initDate && res.data().initDate.toDate();
				const endDate = res.data().endDate && res.data().endDate.toDate();
				// { ...res.data(), initDate: `${initDate.getFullYear()}/${initDate.getMonth() < 10 ? "0" + initDate.getMonth() : initDate.getMonth()}/${initDate.getDate() < 10 ? "0" + initDate.getDate() : initDate.getDate()}`, endDate }
				setInitialData({ ...res.data(), initDate, endDate });
			});
	};

	const fetchStores = async () => {
		firebase
			.firestore()
			.collection("stores")
			.where("owner", "==", authUser.owner)
			.get()
			.then((querySnapshot) => {
				const s = querySnapshot.docs?.map((d) => ({ value: d.id, label: d.data().name, _geoloc: d.data()._geoloc }));
				setStores(s);
			});
	};

	const fetchProducts = async () => {
		firebase
			.firestore()
			.collection("products")
			.where("owner", "==", authUser.owner)
			.get()
			.then((querySnapshot) => {
				const s = querySnapshot.docs?.map((d) => ({ ref: d.ref, id: d.id, ...d.data() }));
				console.log(s);
				setProducts(s);
			});
	};

	const ProductSchema = Yup.object().shape({
		name: Yup.string().min(3, "El nombre de la promoción debe tener más de 2 caracteres").max(50, "El nombre de la promoción no puede superar los 50 caracteres").required("Pon un nombre a la Promoción"),
	});

	const deleteProduct = async () => {
		setLoading(true);
		firebase
			.firestore()
			.collection("promotions")
			.doc(id)
			.delete()
			.then((res) => {
				setLoading(false);
				alert("La Promoción se ha eliminado con éxito");
				history.push("/products");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error al eliminar la Promoción ", JSON.stringify(err));
			});
	};

	const submitForm = async (values) => {
		setLoading(true);
		const geo = values.stores.map((s) => stores.find((el) => el.value === s)?._geoloc);
		const storesDetails = values.stores.map((s) => {
			const name = stores.find((el) => el.value === s).label;
			const id = stores.find((el) => el.value === s).value;
			return { name, id };
		});
		id
			? firebase
					.firestore()
					.collection("promotions")
					.doc(id)
					.update({ ...values, _geoloc: geo, storesDetails, owner: authUser.owner })
					.then((res) => {
						setLoading(false);
						alert("La Promoción se ha actualizado con éxito");
						history.push("/promotions");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					})
			: firebase
					.firestore()
					.collection("promotions")
					.add({ ...values, _geoloc: geo, storesDetails, owner: authUser.owner })
					.then((res) => {
						setLoading(false);
						alert("La Promoción se ha agregado con éxito");
						history.push("/promotions");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					});
	};

	return (
		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={ProductSchema} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched, setValues }) => (
				<Form>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/promotions")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									{id ? initialData.name : "Nueva Promoción"}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{id && !loading && (
									<Button
										onClick={() => deleteProduct()}
										type="submit"
										style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
										primary
										color="status-critical">
										Eliminar
									</Button>
								)}
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="75%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box>
										<label htmlFor="name">Nombre de la promoción</label>
										<Field className="basicInput" id="name" name="name" placeholder="Nombre de la promoción..." />
										{errors.name && touched.name ? (
											<Text size="16px" color="status-critical">
												{errors.name}
											</Text>
										) : null}
									</Box>
									<TextBox name="description" label="Descripción" />
								</Box>
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Disponibilidad
											</Heading>
										</Box>
									</Box>
									<CheckBoxBox name="allProducts" label="Promoción disponible para todos los productos" />
									<Collapsible open={!values.allProducts}>
										<Box height="300px" gap="small" background="#f7f7f7" round="small" pad="small">
											<TextInput placeholder="Buscar Productos" style={{ backgroundColor: "white" }} onChange={(ev) => setSearch(ev.target.value)} />
											{products
												.filter((el) => el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
												.map((product, i) => {
													return (
														<Box key={i} direction="row" gap="small">
															<CheckBox
																checked={values.products.includes(product.id)}
																onChange={(ev) => (!ev.target.checked ? setValues({ ...values, products: values.products.filter((el) => el !== product.id) }) : setValues({ ...values, products: [...values.products, product.id] }))}
															/>
															<Text>{product.name}</Text>
														</Box>
													);
												})}
										</Box>
									</Collapsible>
									{/* <CheckBoxBox name="allStores" label="Promoción disponible para todos los establecimientos" /> */}
								</Box>

								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Imagen destacada
											</Heading>
										</Box>
									</Box>

									<ImageBox name="image" />
								</Box>
							</Box>

							<Box gap="medium" width="25%">
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Inactivo"
										name="status"
										label="Status"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ value: true, label: "Activo" },
											{ value: false, label: "Inactivo" },
										]}
									/>
									<Text size="12px">El estado de la promoción permitira a los usuarios verla en tu negocio</Text>
								</Box>

								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<Heading margin="0px" level="4" color="#170747">
										Fechas
									</Heading>
									<Text size="12px">Fechas en las que la promoción estará disponible</Text>
									<DateBox name="initDate" label="Fecha de Inicio" />
									<Collapsible open={!values.unlimited}>
										<DateBox name="endDate" label="Fecha Final" />
									</Collapsible>
									<CheckBoxBox name="unlimited" label="Sin fecha final" />
								</Box>

								<Box background="white" gap="small" border={{ side: "top", color: "brand", size: "7px" }} pad="medium" elevation="medium" round="10px">
									<Heading margin="0px" level="4" color="#170747">
										Características
									</Heading>
									<Text size="12px">En esta sección podrás configurar tu promoción</Text>

									<SelectBox
										placeholder="Inactivo"
										name="type"
										label="Tipo de Promoción"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ label: "Porcentaje de Descuento", value: "DISCOUNTPERCENTAJE" },
											{ label: "Cantidad de Descuento", value: "DISCOUNTAMOUNT" },
											{ label: "Sorteo", value: "RAFFLE" },
											{ label: "2x1", value: "2X1" },
										]}
									/>
									<Collapsible open={values.type === "DISCOUNTPERCENTAJE" || values.type === "DISCOUNTAMOUNT"}>
										<label htmlFor="name">{values.type === "DISCOUNTAMOUNT" ? "Descuento en Euros" : "Porcentaje de Descuento"}</label>
										<Field type="number" className="basicInput" id="amount" name="amount" placeholder={values.type === "DISCOUNTAMOUNT" ? "Euros de Descuento..." : "Porcentaje de Descuento..."} />
									</Collapsible>
									<SelectBox
										placeholder="Inactivo"
										name="availability"
										label="Disponibilidad"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ label: "Ilimitada", value: "UNLIMITED" },
											{ label: "Limitada", value: "LIMITED" },
										]}
									/>
									<Collapsible open={values.availability === "LIMITED"}>
										<label htmlFor="name">Unidades disponibles</label>
										<Field type="number" className="basicInput" id="units" name="units" placeholder="Unidades Disponibles..." />
									</Collapsible>
								</Box>

								<Box background="white" gap="small" border={{ side: "top", color: "brand", size: "7px" }} pad="medium" elevation="medium" round="10px">
									<Heading margin="0px" level="4" color="#170747">
										Disponibilidad
									</Heading>
									<Text size="12px">Selecciona en que establecimientos estará disponible este producto</Text>
									{/* <FieldArray name="stores">
									<CheckBoxBox label=""/>
									</FieldArray> */}
									<SelectBox multiple closeOnChange={false} placeholder="Selecciona Establecimiento..." name="stores" label="Establecimientos" labelKey="label" valueKey={{ key: "value", reduce: true }} options={stores} />
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default PromotionForm;
