import React, { useState, useEffect, useContext } from "react";
import { Box, Text, Layer, Image, Button, TextInput } from "grommet";

import firebase from "firebase/app";

import algoliasearch from "algoliasearch/lite";

const OwnershipRequest = ({ modalCallback, refreshCallback, owner, store }) => {
	const [data, setData] = useState({});

	useEffect(() => {
		getResults();
	}, []);

	const getResults = async () => {
		firebase
			.firestore()
			.collection("users")
			.doc(owner)
			.get()
			.then((res) => {
				setData({ ...res.data(), id: res.id, ref: res.ref });
			});
	};

	const accept = async () => {
		firebase
			.firestore()
			.collection("stores")
			.doc(store.id)
			.update({
				_tags: ["claimed"],
				owner: owner
			})
			.then((res) => {
				alert("Se ha asignado el establecimiento con éxito");
				refreshCallback(false);
			})
			.catch((err) => {
				alert("Ha ocurrido un error al asignar el establecimiento");
			});
	};
	const reject = async () => {
		firebase
			.firestore()
			.collection("stores")
			.doc(store.id)
			.update({
				_tags: ["not_claimed"],
				owner: null,
			})
			.then((res) => {
				alert("Se ha rechazado la asignación del establecimiento");
				refreshCallback(false);
			})
			.catch((err) => {
				alert("Ha ocurrido un error");
			});
	};

	return (
		<Layer>
			<Box pad="medium" gap="medium" width="large">
				<Box overflow="scroll" height="350px" width="100%" background="#f3f3f3" round="small" pad="medium" gap="small">
					<Text>El siguiente usuario ha solicitado la titularidad de {store.name}</Text>
					<Text>{data.email}</Text>
				</Box>
				<Box direction="row" justify="between">
					<Button
						onClick={() => {
							modalCallback(false);
						}}
						style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
						primary
						color="gainsboro">
						Cancelar
					</Button>

					<Box direction="row" gap="small">
						<Button
							onClick={() => {
								reject();
							}}
							style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
							primary
							color="status-critical">
							Rechazar
						</Button>
						<Button
							onClick={() => {
								accept();
							}}
							style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
							primary
							color="brand">
							Aceptar
						</Button>
					</Box>
				</Box>
			</Box>
		</Layer>
	);
};

export default OwnershipRequest;
