import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput, Collapsible } from "grommet";
import { CaretPrevious, Trash } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import ImageBox from "../components/ImageBox";
import SelectBox from "../components/SelectBox";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";
import CheckBoxBox from "../components/CheckBoxBox";
import DateBox from "../components/DateBox";

const ContentForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();
	const [initialData, setInitialData] = useState({
		name: "",
		content: "",
		link: "",
		association: "",
		tags: "",
		image: "",
		publishDate: "",
		status: false,
		type: "TIP",
	});

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		id && fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("contents")
			.doc(id)
			.get()
			.then((res) => {
				setInitialData(res.data());
			});
	};

	const ProductSchema = Yup.object().shape({
		title: Yup.string().min(3, "El título del contenido debe tener más de 2 caracteres").max(100, "El título del contenido no puede superar los 100 caracteres").required("Pon un título"),
	});

	const deleteItem = async () => {
		setLoading(true);
		firebase
			.firestore()
			.collection("contents")
			.doc(id)
			.delete()
			.then((res) => {
				setLoading(false);
				alert("El Contenido se ha eliminado con éxito");
				history.push("/products");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error al eliminar el Contenido ", JSON.stringify(err));
			});
	};

	const submitForm = async (values) => {
		setLoading(true);

		id
			? firebase
					.firestore()
					.collection("contents")
					.doc(id)
					.update({ ...values, owner: authUser.owner, association: authUser.association, publishDate: firebase.firestore.FieldValue.serverTimestamp() })
					.then((res) => {
						setLoading(false);
						alert("El Contenido se ha actualizado con éxito");
						history.push("/contents");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					})
			: firebase
					.firestore()
					.collection("contents")
					.add({ ...values, owner: authUser.owner, association: authUser.association, publishDate: firebase.firestore.FieldValue.serverTimestamp() })
					.then((res) => {
						setLoading(false);
						alert("El Contenido se ha creado con éxito");
						history.push("/contents");
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						alert("Ha sucedido un error ", JSON.stringify(err));
					});
	};

	return (
		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={ProductSchema} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched }) => (
				<Form>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/contents")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									{id ? initialData.name : "Nuevo Contenido"}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{id && !loading && (
									<Button onClick={() => deleteItem()} type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="status-critical">
										Eliminar
									</Button>
								)}
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="75%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box>
										<label htmlFor="title">Título</label>
										<Field className="basicInput" id="title" name="title" placeholder="Título..." />
										{errors.title && touched.title ? (
											<Text size="16px" color="status-critical">
												{errors.title}
											</Text>
										) : null}
									</Box>
									<TextBox name="content" label="Contenido" />
								</Box>

								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Imagen destacada
											</Heading>
										</Box>
									</Box>

									<ImageBox name="image" />
								</Box>
							</Box>

							<Box gap="medium" width="25%">
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Inactivo"
										name="status"
										label="Estado"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ value: true, label: "Publicado" },
											{ value: false, label: "Borrador" },
										]}
									/>
									<Text size="12px">El estado del contenido permitira a los usuarios verlo publicamente</Text>
								</Box>

								<Box background="white" gap="small" border={{ side: "top", color: "brand", size: "7px" }} pad="medium" elevation="medium" round="10px">
									<Heading margin="0px" level="4" color="#170747">
										Tipo
									</Heading>
									<Text size="12px">Puedes seleccionar el tipo de contenido</Text>

									<SelectBox
										placeholder="Inactivo"
										name="type"
										label="Tipo de Contenido"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ label: "Tips", value: "TIP" },
											{ label: "Noticia", value: "ARTICLE" },
										]}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default ContentForm;
