import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput } from "grommet";
import { CaretPrevious, Trash } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import SelectBox from "../components/SelectBox";
import Dropzone from "react-dropzone";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";
import GoogleMapReact from "google-map-react";
import axios from "axios";

const UserForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();

	const defaultMapProps = {
		center: {
			lat: 40.4167,
			lng: -3.70325,
		},
		zoom: 13,
	};

	const [initialData, setInitialData] = useState({
		name: "",
		lastName: "",
		email: "",
		contactEmail: "",
		password: "",
		associationNotifications: false,
		commercialNotifications: false,
		phone: "",
		status: true,
		role: "User"
	});
	const [coordinates, setCoordinates] = useState(null);
	const [urlModal, setUrlModal] = useState(false);
	const [url, setUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [loadingCoordinates, setLoadingCoordinates] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);

	useEffect(() => {
		id && fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("users")
			.doc(id)
			.get()
			.then((res) => {
				setInitialData(res.data());
			});
	};

	const StoreSchema = Yup.object().shape({
		name: Yup.string().min(3, "El nombre del Usuario debe tener más de 2 caracteres").max(50, "El nombre del establecimiento no puede superar los 50 caracteres").required("Pon un nombre del establecimiento"),
		// links: Yup.string().url("La URL introducida no es válida. Ejemplo: https://urlalestablecimiento.com"),
	});


	const deleteUser = async () => {
		setLoading(true);
		firebase
			.firestore()
			.collection("users")
			.doc(id)
			.delete()
			.then((res) => {
				setLoading(false);
				alert("El establecimiento se ha eliminado con éxito");
				history.push("/users");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error al eliminar el establecimiento ", JSON.stringify(err));
			});
	};

	const onKeyDown = (keyEvent) => {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
		}
	};

	const newUser = (values) => {
		setLoading(true);

		console.log(values)
		const variables = {
			email: values.email,
			password: values.password,
			name: values.name,
			disabled: !values.status,
		};
		console.log(variables);
		if (values.password === null) {
			alert("Debes introducir una contraseña para crear un usuario o seleccionar la creación automática de contraseña.");
			setLoading(false);
		} else {
			axios
				.post("https://europe-west1-retailhub-6602d.cloudfunctions.net/createUser", {
					method: "POST",
					body: JSON.stringify(variables),
				})
				.then((response) => {
					console.log("Success:", response);
					if (!response.data.code) {
						console.log(response.data);
						alert("ALL GOOD")
						firebase
							.firestore()
							.collection("users")
							.doc(response.data)
							.set({
								...values,
								owner: response.data
							})
							.then((res) => history.push(`/users`));
					}
					if (response.data.code === "auth/invalid-password") {
						alert("La contraseña debe tener al menos 6 caracteres");
					}
					if (response.data.code === "auth/invalid-email") {
						alert("El formato del email no es el adecuado");
					}
					if (response.data.code === "auth/email-already-exists") {
						alert("El email introducido ya se encuentra en la Base de Datos");
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		}
	};

	const editUser = (values) => {
		setLoading(true);

		const variables = {
			email: values.email,
			password: values.password,
			name: values.name,
			disabled: values.status,
			userID: id,
		};
		if (values.email !== initialData.email) {
			axios
				.post("https://europe-west1-retailhub-6602d.cloudfunctions.net/updateUser", {
					method: "POST",
					body: JSON.stringify(variables),
				})
				.then((response) => {
					// console.log('Success:', response)
					if (response.data.code === null) {
						console.log(response.data);
						firebase
							.firestore()
							.collection("users")
							.doc(response.data)
							.set({
								...values
							});
						// history.push("/users")
					}
					if (response.data.code === "auth/invalid-password") {
						alert("La contraseña debe tener al menos 6 caracteres");
					}
					if (response.data.code === "auth/invalid-email") {
						alert("El formato del email no es el adecuado");
					}
					if (response.data.code === "auth/email-already-exists") {
						alert("El email introducido ya se encuentra en la Base de Datos");
					}
					if (response.data.code === "auth/invalid-uid") {
						alert("El usuario que intenta editar no existe en la Base de Datos");
					}
					setLoading(false);
					alert("Usuario editado");
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			firebase
				.firestore()
				.collection("users")
				.doc(id)
				.update({
					...values,
					...variables,
				})
				.then((res) => {
					setLoading(false);
					history.push(`/users`);
				}).catch( err => {
					setLoading(false)
					alert("HA ocurrido un error " + err)
				})
		}
	};

	
	return (
		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={StoreSchema} onSubmit={async (values) => (id ? editUser(values) : newUser(values))}>
			{({ values, errors, touched }) => (
				<Form onKeyDown={onKeyDown}>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/users")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									{id ? initialData?.name : "Nuevo Usuario"}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{id && !loading && (
									<Button onClick={() => deleteUser()} type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="status-critical">
										Eliminar
									</Button>
								)}
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="75%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row" gap="medium">
										<Box flex>
											<label htmlFor="name">Nombre </label>
											<Field className="basicInput" id="name" name="name" placeholder="Nombre..." />
											{errors.name && touched.name ? (
												<Text size="16px" color="status-critical">
													{errors.name}
												</Text>
											) : null}
										</Box>
										<Box flex>
											<label htmlFor="lastName">Apellidos </label>
											<Field className="basicInput" id="lastName" name="lastName" placeholder="Apellidos..." />
										</Box>
									</Box>
									<Box flex>
										<label htmlFor="email">Email </label>
										<Field className="basicInput" id="email" name="email" placeholder="Email..." />
									</Box>
									<Box flex>
										<label htmlFor="password">Contraseña </label>
										<Field className="basicInput" id="password" name="password" placeholder="Contraseña..." />
									</Box>
								</Box>

								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Información Adicional
											</Heading>
										</Box>
									</Box>
									<Box>
										<label htmlFor="phone">Teléfono </label>
										<Field className="basicInput" id="phone" name="phone" placeholder="Teléfono..." />
									</Box>
								</Box>
							</Box>

							<Box gap="medium" width="25%">
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Inactivo"
										name="status"
										label="Status"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ value: true, label: "Activo" },
											{ value: false, label: "Inactivo" },
										]}
									/>
									<Text size="12px">El estado indica si es un usuario activo o inactivo</Text>
								</Box>

								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Inactivo"
										name="role"
										label="Rol"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ value: "User", label: "Usuario" },
											{ value: "Merchant", label: "Comerciante" },
											{ value: "Association", label: "Asociación" },
											{ value: "Admin", label: "Administrador" },
										]}
									/>
									<Text size="12px">El estado indica si es un usuario activo o inactivo</Text>
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default UserForm;
