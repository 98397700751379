import React, { useState, useContext, useEffect, useRef } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput, Tabs, Tab } from "grommet";
import { CaretPrevious, Trash, Up, Down, Info, Map } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import SelectBox from "../components/SelectBox";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import decodePolyline from "decode-google-map-polyline";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";
import GoogleMapReact from "google-map-react";
import { GoogleMap, LoadScript, Marker, Polyline } from "@react-google-maps/api";
import { encode } from "@googlemaps/polyline-codec";
import axios from "axios";
import ImageBox from "../components/ImageBox";
import SearchStoreModal from "../components/SearchStoreModal";

const RouteForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();
	const [tabActive, setTabActive] = useState(0);
	const [zoom, setZoom] = useState(15)
	const [center, setCenter] = useState({ lat: 40.4167, lng: -3.70325 })
	const [mode, setMode] = useState("Stores")
	const [generatingRoute, setGeneratingRoute] = useState(false)
	const mapRef = useRef()
	const polylineRef = useRef()
	const defaultMapProps = {
		center: {
			lat: 40.4167,
			lng: -3.70325,
		},
		zoom: 13,
	};

	const [initialData, setInitialData] = useState({
		name: "",
		description: "",
		time: 0,
		image: "",
		status: false,
		steps: [],
	});
	const [coordinates, setCoordinates] = useState(null);
	const [urlModal, setUrlModal] = useState(false);
	const [storeModal, setStoreModal] = useState(false)
	const [loading, setLoading] = useState(false);
	const [loadingCoordinates, setLoadingCoordinates] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);

	const [coord, setCoord] = useState(null);

	const mapStyle = [
		{
			featureType: "administrative",
			elementType: "labels",
			// stylers: [{ visibility: "off" }],
		},
		{
			featureType: "poi",
			elementType: "labels",
			stylers: [{ visibility: "off" }],
		},
		{
			featureType: "water",
			elementType: "labels",
			stylers: [{ visibility: "off" }],
		},
		{
			featureType: "road",
			elementType: "labels",
			// stylers: [{ visibility: "off" }],
		},
		{
			featureType: "landscape",
			elementType: "labels",
			stylers: [
				{
					visibility: "off",
				},
			],
		},
	];

	useEffect(() => {
		id && fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("routes")
			.doc(id)
			.get()
			.then((res) => {
				setInitialData(res.data());
				res.data().route && setCoord(decodePolyline(res.data().route));
			});
	};

	const FormSchema = Yup.object().shape({
		name: Yup.string().min(3, "Este campo debe tener mas de 2 caracteres").max(50, "Este campo no puede superar los 50 caracteres").required("Este campo es obligatorio"),
		// links: Yup.string().url("La URL introducida no es válida. Ejemplo: https://urlalestablecimiento.com"),
	});

	const imageDropped = (file, values) =>
		new Promise((resolve, reject) => {
			setLoadingImage(true);
			console.log(file);
			const fileRef = firebase.storage().ref().child(`stores/images/${new Date().valueOf()}${file[0].name}`);
			fileRef
				.put(file[0])
				.then((res) => {
					firebase
						.storage()
						.ref(res.ref.fullPath)
						.getDownloadURL()
						.then((res) => resolve(res))
						.catch((err) => reject(err));
				})
				.catch((err) => reject(err));
		});

	const deleteItem = async () => {
		setLoading(true);
		firebase
			.firestore()
			.collection("routes")
			.doc(id)
			.delete()
			.then((res) => {
				setLoading(false);
				alert("El elemento se ha eliminado con éxito");
				history.push("/routes");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error al eliminar el elemento ", JSON.stringify(err));
			});
	};

	const submitForm = async (values) => {
		setLoading(true);
		console.log(values)
		id
			? firebase
				.firestore()
				.collection("routes")
				.doc(id)
				.update({ ...values, owner: authUser.uid })
				.then((res) => {
					setLoading(false);
					alert("El elemento se ha actualizado con éxito");
					history.push("/routes");
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
					alert("Ha sucedido un error ", JSON.stringify(err));
				})
			: firebase
				.firestore()
				.collection("routes")
				.add({ ...values, owner: authUser.uid })
				.then((res) => {
					setLoading(false);
					alert("El elemento se ha agregado con éxito");
					history.push("/routes");
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
					alert("Ha sucedido un error ", JSON.stringify(err));
				});
	};

	const onKeyDown = (keyEvent) => {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
		}
	};

	const generateRoute = async (steps) => {
		setGeneratingRoute(true)
		const origin = [steps[0].coordinates.latitude, steps[0].coordinates.longitude];
		const destination = [steps[steps.length - 1].coordinates.latitude, steps[steps.length - 1].coordinates.longitude];
		const mid = steps.slice(1, steps.length - 1);
		const waypoints = mid.map((el) => [el.coordinates.latitude, el.coordinates.longitude].join("%2C")).join("|");

		const params =
			mid.length > 0
				? `origin=${origin.join(",")}&destination=${destination.join(",")}&waypoints=via:${waypoints}`
				: `origin=${origin.join(",")}&destination=${destination.join(",")}`;

		const resp = await fetch("https://europe-west1-retailhub-6602d.cloudfunctions.net/getRoute", {
		// const resp = await fetch("http://localhost:5001/retailhub-6602d/europe-west1/getRoute", {
			body: params,
			method: "POST",
		})
			.then((res) => res.json())
			.catch((err) => { });

		if (resp?.routes[0]?.overview_polyline?.points) {
			alert("Se ha encontrado una ruta a pie con éxito");
			console.log(decodePolyline(resp.routes[0].overview_polyline.points))
			console.log(resp.routes[0].overview_polyline.points)
			setCoord(decodePolyline(resp.routes[0].overview_polyline.points));
			setGeneratingRoute(false)
			return resp.routes[0].overview_polyline.points;
		} else {
			alert("No se ha encontrado ninguna ruta a pie entre los puntos seleccionados");
			setGeneratingRoute(false)
			return false;
		}
	};

	return (

		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={FormSchema} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched, setFieldValue }) => (
				<Form onKeyDown={onKeyDown}>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/routes")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									{id ? initialData.name : "Nueva Ruta"}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{id && !loading && (
									<Button
										onClick={() => deleteItem()}
										type="submit"
										style={{
											color: "white",
											fontWeight: "600",
											textAlign: "center",
											paddingLeft: 15,
											paddingRight: 15,
											height: 35,
											borderRadius: 5,
											alignItems: "center",
											justifyContent: "center",
										}}
										primary
										color="status-critical"
									>
										Eliminar
									</Button>
								)}
								{!loading ? (
									<Button
										type="submit"
										style={{
											color: "white",
											fontWeight: "600",
											textAlign: "center",
											paddingLeft: 15,
											paddingRight: 15,
											height: 35,
											borderRadius: 5,
											alignItems: "center",
											justifyContent: "center",
										}}
										primary
										color="brand"
									>
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>
						<Tabs onActive={(ev) => setTabActive(ev)} alignControls="start" >
							<Tab plain title={<Button primary={tabActive === 0} label="Información" icon={<Info />} style={{ marginBottom: 15, border: 0, borderRadius: 7, paddingLeft: 15 }} margin={{ right: "small" }} />}>
								<Box gap="medium" direction="row" align="start">
									<Box flex="grow" background="white" pad="medium"  elevation="medium" gap="small" round="10px">
										<Box>
											<label htmlFor="name">Nombre de la Ruta</label>
											<Field className="basicInput" id="name" name="name" placeholder="Nombre de la Ruta..." />
											{errors.name && touched.name ? (
												<Text size="16px" color="status-critical">
													{errors.name}
												</Text>
											) : null}
										</Box>
										<TextBox name="description" label="Descripción" />
										<Box direction="row" gap="medium">
											<Box flex="grow">
												<label htmlFor="time">Duración de la Ruta en minutos</label>
												<Field className="basicInput" id="time" type="number" name="time" placeholder="Duración..." />
											</Box>
											<Box flex="grow">
												<SelectBox
													placeholder="Inactivo"
													name="status"
													label="Status"
													labelKey="label"
													valueKey={{ key: "value", reduce: true }}
													options={[
														{ value: true, label: "Activo" },
														{ value: false, label: "Inactivo" },
													]}
												/>
												<Text size="12px">El estado indicará si la ruta se mostrará o no...</Text>
											</Box>
										</Box>
									</Box>

									<Box background="white" pad="medium" elevation="medium" width={"medium"} gap="small" round="10px">
										<Box direction="row">
											<Box flex>
												<Heading margin="0px" level="4" color="#170747">
													Imágen Destacada
												</Heading>
											</Box>
											<Box onClick={() => setUrlModal(true)}>
												<Text size="small" color="secondary" style={{ textDecoration: "underline" }}>
													Añadir desde una url
												</Text>
											</Box>
										</Box>

										<ImageBox name="image" />
									</Box>


								</Box>
							</Tab>


							{/* BLOQUE PRINCIPAL*/}


							<Tab plain title={<Button primary={tabActive === 1} label="Ruta" icon={<Map />} style={{ marginBottom: 15, border: 0, borderRadius: 7, paddingLeft: 15 }} margin={{ right: "small" }} />}>
								<Box gap="medium" >
									<Box background="white" gap="small" border={{ side: "top", color: "brand", size: "7px" }} pad="medium" elevation="medium" round="10px">
										<Heading margin="0px" level="4" color="#170747">
											Diseña de Ruta
										</Heading>
										<Text size="12px">Haz click sobre el mapa para añadir una parada o busca entre los establecimientos en la base de datos</Text>
										<FieldArray name="steps">
											{({ insert, remove, push, move }) => (
												<Box gap="medium">
													<Box height="360px" width="100%">
														<LoadScript googleMapsApiKey="AIzaSyA7522FCbabm1HahH84jN8_GKK6uw4lJmE">
															<GoogleMap
																mapContainerStyle={{
																	height: "400px",
																	width: "100%",
																}}
																options={{
																	mapTypeControl: false,
																	streetViewControl: false,
																	fullscreenControl: false,
																	backgroundColor: "#fefefe",
																	// zoom: zoom,

																	styles: mapStyle,
																}}
																ref={mapRef}
																// onZoomChanged={(e) => setZoom(mapRef.current.state.map.zoom)}
																zoom={zoom}
																center={center}
																onClick={(ev) => {
																	if (mode === "Route") {
																		const c = [...(coord || []), { lat: ev.latLng.lat(), lng: ev.latLng.lng() }]
																		const en = encode(c.map((e) => [e.lat, e.lng]))
																		setFieldValue("route", en)
																		setCoord(c)
																	} else {

																		// setCenter({ lat: ev.latLng.lat(), lng: ev.latLng.lng() })

																		push({ name: "", description: "", image: "", mapIcon: "", id: "", coordinates: { latitude: ev.latLng.lat(), longitude: ev.latLng.lng() } })
																	}
																}}
															>
																{coord && (
																	<Polyline
																		// coordinates={coord}
																		path={coord}
																		strokeColor="#000" // fallback for when `strokeColors` is not supported by the map-provider
																		strokeWidth={6}
																		editable={true}
																		onDragEnd={(ev) => console.log(ev)}
																		onDrag={(ev) => console.log(ev)}
																		onMouseUp={(ev) => {

																			const c = polylineRef.current.state.polyline.latLngs.getArray()[0].Fd.map((e) => ({ lat: e.lat(), lng: e.lng() }))
																			const en = encode(c.map((e) => [e.lat, e.lng]))
																			setFieldValue("route", en)
																		}}
																		ref={polylineRef}
																		options={{
																			strokeColor: "#ff2527",
																			strokeOpacity: 0.55,
																			strokeWeight: 5,
																			icons: [
																				{
																					icon: "https://i.morioh.com/RFG2le2.png",
																					offset: "0",
																					repeat: "10px",
																				},
																			],
																		}}
																	/>
																)}
																{values?.steps?.map((item, i) => (
																	<Marker
																		key={i}
																		draggable={true}
																		label={`${i + 1}`}
																		shape={"MarkerShapeCircle"}
																		onDragEnd={(ev) => {
																			setFieldValue(`steps.${i}.coordinates`, { latitude: ev.latLng.lat(), longitude: ev.latLng.lng() })
																		}}
																		// icon={item.mapIcon ? item.mapIcon : null}
																		
																		position={{
																			lat: item?.coordinates?.latitude,
																			lng: item?.coordinates?.longitude,
																		}}
																	/>
																))}
															</GoogleMap>
														</LoadScript>
													</Box>
													<Box align="start" justify="between" direction="row">
														<Box direction="row" gap="small">
															<Box border={{ size: "2px", color: "secondary" }} round="5px">
																<Button
																	onClick={() => setStoreModal(true)}
																	style={{
																		fontWeight: "600",
																		borderWidth: 2,
																		borderColor: "#170747",
																		textAlign: "center",
																		paddingLeft: 15,
																		paddingRight: 15,
																		height: 35,
																		borderRadius: 5,
																		alignItems: "center",
																		justifyContent: "center",
																	}}
																	color="#170747"
																>
																	Añadir Establecimiento
																</Button>
															</Box>
															<Box border={{ size: "2px", color: "secondary" }} round="5px">
																<Button
																	onClick={() => setMode(mode === "Route" ? "Stores" : "Route")}
																	style={{
																		fontWeight: "600",
																		borderWidth: 2,
																		borderColor: "#170747",
																		textAlign: "center",
																		paddingLeft: 15,
																		paddingRight: 15,
																		height: 35,
																		borderRadius: 5,
																		alignItems: "center",
																		justifyContent: "center",
																	}}
																	color="#170747"
																>
																	{mode === "Route" ? "Establecimientos" : "Ruta"}
																</Button>
															</Box>
														</Box>
														<Button
															onClick={() => generateRoute(values.steps).then((res) => res && setFieldValue("route", res))}
															style={{
																fontWeight: "600",
																borderWidth: 2,
																backgroundColor: "#FAC149",
																textAlign: "center",
																paddingLeft: 15,
																paddingRight: 15,
																height: 38,
																borderRadius: 5,
																alignItems: "center",
																fontSize: 14,
																justifyContent: "center",
																color: "white",
																borderColor: "#FAC149",
																lineHeight: "14px"
															}}
															label="Generar Ruta"
														/>
													</Box>
													{storeModal && <SearchStoreModal selectedCallback={(ev) => {
														push({ name: ev.name, description: ev.description, image: ev.images[0] || "", mapIcon: ev.mapIcon || "", id: ev.objectID, coordinates: { latitude: ev._geoloc.lat, longitude: ev._geoloc.lng } })
														setStoreModal(false)
													}} modalCallback={() => setStoreModal(false)} />}
													<Box gap="small">
														{values?.steps?.length > 0 ? (
															values?.steps?.map((variant, i) => (
																<Box key={i} direction="row" background="#f2f2f2" gap="small" pad="small" round="small">
																	<Box style={{ flex: 1 }} gap="small" direction="row">
																		<Box style={{ flex: 1.5 }} height="medium" background="white" pad="medium" elevation="medium" gap="small" round="10px">
																			<Box direction="row">
																				<Box onClick={() => setUrlModal(true)}>
																					<Text size="small" color="secondary" style={{ textDecoration: "underline" }}>
																						Añadir desde una url
																					</Text>
																				</Box>
																			</Box>

																			<ImageBox name={`steps.${i}.image`} />
																		</Box>
																		<Box style={{ flex: 4 }} gap="small" pad={{ left: "medium" }}>
																			<Heading level="4" margin={{ bottom: "5px", top: "15px" }}>Parada {i + 1}</Heading>
																			<Field className="basicInput" id={`steps.${i}.name`} name={`steps.${i}.name`} placeholder="Nombre..." />
																			<Field className="basicInput" id={`steps.${i}.description`} name={`steps.${i}.description`} placeholder="Descripción..." />
																			<Field className="basicInput" id={`steps.${i}.mapIcon`} name={`steps.${i}.mapIcon`} placeholder="Enlace al Icono..." />
																		</Box>
																	</Box>
																	<Box>
																		<Button hoverIndicator onClick={() => remove(i)} icon={<Trash />} />
																		<Button disabled={i === 0} hoverIndicator onClick={() => move(i, i - 1)} icon={<Up />} />
																		<Button disabled={i === values.steps.length - 1} hoverIndicator onClick={() => move(i, i + 1)} icon={<Down />} />
																	</Box>
																</Box>
															))
														) : (
															<Box height="150px" background="#f8f8f8" round="small" align="center" justify="center">
																<Text>Sin Paradas</Text>
															</Box>
														)}
													</Box>
												</Box>
											)}
										</FieldArray>
									</Box>
								</Box>
							</Tab>
						</Tabs>
					</Box>
				</Form>
			)
			}
		</Formik >

	);
};

export default RouteForm;

const CustomMarker = ({ lat, lng }) => (
	<div
		lat={lat}
		lng={lng}
		style={{
			backgroundImage: "url(https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png)",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			height: 40,
			width: 40,
			marginTop: -40,
			marginLeft: -20,
		}}
	/>
);
