import React, { useState, useEffect, useContext } from 'react'
import {
    Box,
    Button,
    Layer,
    Heading,
    TextInput,
    Select,
    TextArea,
    Text,
    RadioButtonGroup,
    Keyboard
} from 'grommet'
// import { UserContext } from '../context'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'
import firebase from 'firebase'
import { Chat } from 'grommet-icons'
import UserContext from '../context/UserContext'
import { Editor } from '@tinymce/tinymce-react';

const SendMailModal = ({ visible, closeCallback, setVisible, emailList = [], collection, id }) => {

    const { authUser } = useContext(UserContext)
    const [recommended, setRecommended] = useState()
    const [manual, setManual] = useState("")
    const [cc, setCc] = useState("Mostrar")
    const [body, setBody] = useState("")
    const [subject, setSubject] = useState("")
    const [mailList, setMailList] = useState([])
    const [loading, setLoading] = useState(false)

    let user
    try {
        user = JSON.parse(localStorage.getItem("user"))
    } catch (err) { }

    const sendEmail = async () => {
        if (mailList.length > 0) {
            setLoading(true)
            const multiple = cc === "Mostrar" ? "cc" : "bcc"
            const completeBody = `${body}`
            const completeHtml = `${body}`
            const completeSubject = `${subject}`
            fetch("https://us-central1-pozzeidon-crm.cloudfunctions.net/sendMail", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    subject: completeSubject,
                    text: completeBody,
                    html: completeHtml,
                    [multiple]: mailList.reduce((acc, it) => it + "," + acc, ""),
                    collection,
                    id,
                    userID: authUser.id
                })
            }).then(res => {

                closeCallback()
                setLoading(false)
            }).catch(err => {
                setLoading(false)

            })
            // const data = await resp.json()
        }
    }

    const addToMailList = email => {
        if (email && !mailList.includes(email.toLowerCase())) {
            setMailList([...mailList, email.toLowerCase()])
            setRecommended(null)
            setManual("")
        }
    }

    const removeFromMailList = email => {
        if (mailList.includes(email)) {
            setMailList(mailList.filter(e => e !== email))
        }
    }

    return (
        <Layer
            full="vertical"
            position="left"
            onEsc={() => setVisible(false)}
            onClickOutside={() => setVisible(false)}
        >
            <Box round="medium" width="large" justify="between">

                <Box height="50px" background="brand" pad="small">
                    <Heading level="3" margin="none">Enviar Mensaje</Heading>
                </Box>

                <Box overflow="scroll" flex={true} pad="medium">
                    <Box gap="medium" flex="grow">


                        {emailList.length > 0 && (
                            <Box>
                                <Label>Destinatarios recomendados</Label>
                                <Box direction="row" flex style={{ minHeight: "auto" }}>
                                    <Box flex fill>
                                        <Select
                                            value={recommended}
                                            options={emailList}
                                            onChange={event =>
                                                // setRecommended(event.value)
                                                addToMailList(event.value)
                                            }
                                        />
                                    </Box>
                                    {/* <Button primary label="+" style={{ border: 0, marginLeft: 10, height: "100%" }} onClick={() => addToMailList(recommended)} /> */}
                                </Box>
                            </Box>
                        )}
                        <Box>
                            <Label>Otros destinatarios</Label>
                            <Keyboard onEnter={() => addToMailList(manual)}>
                                <Box direction="row" style={{ minHeight: "auto" }}>
                                    <TextInput
                                        placeholder="Escribe un email"
                                        value={manual}
                                        onChange={event => setManual(event.target.value)}
                                    />
                                    <Button primary label="+" style={{ border: 0, marginLeft: 10, height: "100%" }} onClick={() => addToMailList(manual)} />
                                </Box>
                            </Keyboard>
                        </Box>
                        <Box>
                            <Label>Lista de destinatarios</Label>
                            <Box
                                direction="row"
                                wrap
                                gap="xsmall"
                                style={{ minHeight: "auto" }}
                            >
                                {mailList.map(email => (
                                    <Box round="7px" key={email + "-tag"} background="brand" pad="xsmall" style={{ marginBottom: 6 }} onClick={() => removeFromMailList(email)}>
                                        <Text size="small">{email}&nbsp;&nbsp;&times;</Text>
                                    </Box>
                                ))}
                            </Box>
                            {mailList.length === 0 && <Text color="dark-6">Sin destinatarios</Text>}
                        </Box>
                        {mailList.length > 1 && (
                            <Box>
                                <Label>Multiples destinatarios</Label>
                                <RadioButtonGroup
                                    name="radio"
                                    direction="row"
                                    gap="none"
                                    options={["Mostrar", "Ocultar"]}
                                    value={cc}
                                    onChange={event => setCc(event.target.value)}
                                    style={{ minHeight: "auto" }}
                                >
                                    {(option, { checked, hover }) => {
                                        let background;
                                        if (checked) { background = "brand" }
                                        else if (hover) { background = "light-4" }
                                        else { background = "light-2" }
                                        return (
                                            <Box background={background} pad="xsmall">
                                                <Text>{option}</Text>
                                            </Box>
                                        );
                                    }}
                                </RadioButtonGroup>
                            </Box>
                        )}
                        <Box>
                            <Label>Asunto</Label>
                            <TextInput
                                placeholder="Asunto del Email"
                                value={subject}
                                onChange={event => setSubject(event.target.value)}
                            />
                        </Box>
                        <Box gap="small">
                            <Label>Cuerpo</Label>
                            <Editor
                                initialValue={body || "<p/>"}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    branding: false,
                                    menubar: 'file edit view insert format tools table tc help',
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={ev => {
                                    setBody(ev)
                                }}
                                apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
                            />
                        </Box>
                    </Box>
                </Box>


                <Box pad="small" background="light-1" direction="row" justify="between">
                    <Button size="small" style={{ borderRadius: 7 }} disabled={loading} label="Cancelar" onClick={() => setVisible(false)} />
                    {loading ?
                        <Loader type="Puff" color="#165781" height={50} width={50} />
                        :
                        <Button size="small" style={{ borderRadius: 7 }} label="Enviar" primary onClick={() => sendEmail()} />
                    }
                </Box>
            </Box>
        </Layer>

    )
}

const Label = styled.label`
    margin-bottom: 10px;
`

export default SendMailModal