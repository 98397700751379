import React, { useEffect, useState, useContext } from "react";
import { Box, Heading, Text, Image, Button, DataTable } from "grommet";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import UserContext from "../context/UserContext";
import _ from "lodash";

const Analytics = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [datap, setDatap] = useState([]);
	const [users, setUsers] = useState([]);
	const [storesWithProducts, setStoresWithProducts] = useState(0);
	const [dataByDistrict, setDataByDistrict] = useState({});
	const [dataByDistrictWithData, setDataByDistrictWithData] = useState({});

	useEffect(() => {
		// fetchData();
	}, []);

	const d = {
		total: 32933,
		withData: 2928,
		withProducts: 58,
		totalProducts: 116,
		dataByDistrict: {
			Centro: 4046,
			Salamanca: 2935,
			Carabanchel: 2390,
			Chamberi: 2185,
			"Ciudad Lineal": 2145,
			Latina: 2025,
			"Puente De Vallecas": 1874,
			Tetuan: 1805,
			Chamartin: 1580,
			"Fuencarral El Pardo": 1467,
			Arganzuela: 1350,
			Usera: 1204,
			Villaverde: 1166,
			"San Blas Canillejas": 1088,
			Retiro: 1080,
			"Moncloa Aravaca": 1043,
			Hortaleza: 993,
			"Villa De Vallecas": 770,
			Moratalaz: 629,
			Vicalvaro: 413,
			Barajas: 376,
			Madrid: 39,
		},
		dataByDistrictWithData: {
			"Puente De Vallecas": 158,
			"San Blas Canillejas": 119,
			"Fuencarral El Pardo": 90,
			Chamartin: 84,
			Salamanca: 77,
			Retiro: 75,
			Barajas: 75,
			Tetuan: 63,
			Hortaleza: 57,
			Carabanchel: 51,
			"Ciudad Lineal": 48,
			Latina: 41,
			"Moncloa Aravaca": 40,
			Madrid: 36,
			"Villa De Vallecas": 31,
			Usera: 27,
			Villaverde: 27,
			Moratalaz: 17,
			Chamberi: 12,
			Vicalvaro: 9,
			Centro: 6,
			Arganzuela: 3,
		},
		users: 123,
		merchants: 66,
		associations: 51,
	};

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("stores")
			.limit(1000)
			.get()
			.then((q) => {
				const el = q.docs.map((el) => el.data());
				// console.log(el);
				const filtered = el.filter((el) => el.images.length > 0);
				const s = _.groupBy(el, "district");
				const o = _.groupBy(filtered, "district");
				// console.log(o);
				setDataByDistrict(s);
				setDataByDistrictWithData(o);
				setData(el);
				setLoading(false);
			});
		firebase
			.firestore()
			.collection("products")
			// .limit(100)
			.get()
			.then((q) => {
				const el = q.docs.map((el) => el.data());
				// console.log(el);
				setDatap(el);
			});
		firebase
			.firestore()
			.collection("users")
			// .limit(100)
			.get()
			.then((q) => {
				const el = q.docs.map((el) => el.data());
				// console.log(el);
				setUsers(el);
			});
		// const products = await firebase
		// 	.firestore()
		// 	.collection("products")
		// 	.where("owner", "==", authUser.owner)
		// 	.get()
		// 	.then((querySnapshot) => ({ total: querySnapshot.docs.length, docs: querySnapshot.docs.map((doc) => doc.data()) }));
		// const promotions = await firebase
		// 	.firestore()
		// 	.collection("promotions")
		// 	.where("owner", "==", authUser.owner)
		// 	.get()
		// 	.then((querySnapshot) => ({ total: querySnapshot.docs.length, docs: querySnapshot.docs.map((doc) => doc.data()) }));
		// const transactions = await firebase
		// 	.firestore()
		// 	.collection("transactions")
		// 	.where("owner", "==", authUser.owner)
		// 	.get()
		// 	.then((querySnapshot) => ({ total: querySnapshot.docs.length, docs: querySnapshot.docs.map((doc) => doc.data()) }));
		// console.log(promotions);
		// await setData({
		// 	products: products.total,
		// 	promotions: promotions.total,
		// 	transactions: transactions.total,
		// 	topPromotion: promotions.docs[0],
		// });
	};

	// console.log(dataByDistrictWithData);
	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			{/* SECCION RESUMEN */}
			<Box gap="medium">
				<Box width="100%" background="white" pad="medium" elevation="medium" round="10px">
					<Heading margin="0px" level="4" color="#170747">
						Analítica de Retail Hub
					</Heading>
					<Box direction="row" style={{ marginTop: 10 }} gap="small">
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{d.total}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Total Comercios
								</Text>
							</Box>
						</Box>
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{d.withData}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Comercios con Datos Contacto
								</Text>
							</Box>
						</Box>
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{d.withProducts}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Comercios Con Productos
								</Text>
							</Box>
						</Box>
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{d.totalProducts}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Total Productos
								</Text>
							</Box>
						</Box>
					</Box>

					<Heading margin="0px" level="4" color="#170747">
						Por Distrito
					</Heading>

					<Box direction="row" style={{ marginTop: 10 }} gap="small">
						<Box flex={true}>
							<Box pad="medium" gap="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading level="4" margin="none" color="#170747">
									Comercios Por distrito
								</Heading>
								<Box flex width="100%">
									{Object.keys(d.dataByDistrict)
										.sort((a, b) => d.dataByDistrict[b] - d.dataByDistrict[a])
										.map((el, i) => {
											return (
												<Box key={i}>
													<Text size="small">
														{el} - {d.dataByDistrict[el]}
													</Text>
													{/* <Text size="small">{el} - {dataByDistrict[el]}</Text> */}
												</Box>
											);
										})}
								</Box>
							</Box>
						</Box>
						<Box flex={true}>
							<Box pad="medium" gap="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading level="4" margin="none" color="#170747">
									Comercios Por distrito Con Datos
								</Heading>
								<Box flex width="100%">
									{Object.keys(d.dataByDistrictWithData)
										.sort((a, b) => d.dataByDistrictWithData[b] - d.dataByDistrictWithData[a])
										.map((el, i) => {
											return (
												<Box key={i}>
													<Text size="small">
														{el} - {d.dataByDistrictWithData[el]}
													</Text>
													{/* <Text size="small">{el} - {dataByDistrict[el]}</Text> */}
												</Box>
											);
										})}
								</Box>
							</Box>
						</Box>
						{/* <Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{datap.length}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Total Productos
								</Text>
							</Box>
						</Box>
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{datap.length}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Total Productos
								</Text>
							</Box>
						</Box> */}
					</Box>

					<Heading margin="0px" level="4" color="#170747">
						Usuarios
					</Heading>

					<Box direction="row" style={{ marginTop: 10 }} gap="small">
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{d.users}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Total de Usuarios
								</Text>
							</Box>
						</Box>
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{d.merchants}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Usuarios Comerciantes
								</Text>
							</Box>
						</Box>
						<Box flex={true}>
							<Box pad="medium" margin={{ bottom: "10px" }} align="center" background="#F6F6F6">
								<Heading margin="0px" style={{ fontSize: 50 }} color="#170747">
									{d.associations}
								</Heading>
								<Text style={{ fontSize: 14 }} color="#170747">
									Usuarios Asociaciones
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Analytics;
