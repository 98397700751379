import React, { useState, useEffect, useContext } from "react";
import { Box, Heading, Text, Layer, Image, Button, DataTable, TextInput } from "grommet";
import { Link, useHistory } from "react-router-dom";
import { Edit, Search } from "grommet-icons";
import firebase from "firebase/app";
import UserContext from "../context/UserContext";
import ClaimStore from "../components/ClaimStore";

const Help = () => {
	const history = useHistory();

	const { authUser } = useContext(UserContext);

	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);

	const [claimModal, toogleClaimModal] = useState(false);

	useEffect(() => {
		firebase
			.firestore()
			.collection("helparticles")
			.get()
			.then((querySnapshot) => {
				const res = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				setData(res);
				setFiltered(res);
			});
	}, []);

	return (
		<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
			{/* SECCION ULTIMAS PROMO */}

			<Box gap="medium" width="100%" background="white" pad="medium" elevation="medium" round="10px">
				<Box direction="row">
					<Box flex={true}>
						<Heading margin="0px" level="3" color="#170747">
							Ayuda
						</Heading>
					</Box>
				</Box>

				<Box height="2px" round="small" background="gainsboro" width="100%" />

				{/* SEARCH BOX */}
				<Box direction="row" gap="small">
					<TextInput icon={<Search />} placeholder="Buscar Artículos..." onChange={(ev) => setFiltered(data.filter((el) => el.name.toLocaleLowerCase().includes(ev.target.value.toLocaleLowerCase()) ))} />
					<Button style={{ border: "2px solid #170747", color: "#170747", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, borderRadius: 5, alignItems: "center", justifyContent: "center" }} color="#170747">
						Buscar
					</Button>
				</Box>
			</Box>

			<Box gap="medium" width="100%" background="white" pad="medium" elevation="medium" round="10px">
				<Box direction="row">
					<Box flex={true}>
						<Heading margin="0px" level="4" color="#170747">
							Resultados
						</Heading>
					</Box>
				</Box>

				<Box height={{ min: "200px" }} width="100%" direction="row" margin={{ top: "15px" }} wrap>
					{filtered.length === 0 ? (
						<Box align="center" justify="center" flex>
							<Heading level="3" style={{ opacity: 0.2 }}>
								No hay resultados
							</Heading>
						</Box>
					) : (
						filtered.map((article, i) => {
							return (
								<Box key={i} width={{max:"46%"}} style={{width:"46%"}} margin={"1%"} height={{max: "small"}} direction="row" align="center" gap="small" >
									<Box flex="grow" width={{max:"small"}} height="small" round="small" overflow="hidden">
										<Image fill fit="cover" src={article.image} />
									</Box>
									<Box gap="small" height="100%" justify="center">
										<Text size="16px" weight="bold">
											{article.name}
										</Text>
										<Text size="12px">{article.content?.replace(/(<([^>]+)>)/gi, "").slice(0,200)}</Text>
										<Box align="start" direction="row" gap="small">

											{authUser.role === "Admin" && <Button icon={<Edit size={"15px"} color="white" style={{margin:0}}/>} onClick={() => history.push("/edithelparticle/" + article.id)} style={{ paddingTop:0, paddingBottom:0, color: "white", fontWeight: "600", textAlign: "center", height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="secondary"/>}
											<Button onClick={() => history.push("/article/" + article.id)} style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
												Ver Video
											</Button>
										</Box>
									</Box>
								</Box>
							);
						})
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Help;
