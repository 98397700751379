import React, { useState, useContext, useEffect } from "react";
import { Box, Heading, Text, Layer, Image, Button, Stack, TextInput, CheckBox } from "grommet";
import { CaretPrevious, Trash } from "grommet-icons";
import { Formik, Field, Form, FieldArray } from "formik";
import TextBox from "../components/TextBox";
import SelectBox from "../components/SelectBox";
import Dropzone from "react-dropzone";
import * as Yup from "yup";

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import UserContext from "../context/UserContext";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";
import GoogleMapReact from "google-map-react";
import axios from "axios";

const NewProductsForm = () => {
	const history = useHistory();
	const { authUser } = useContext(UserContext);
	const { id } = useParams();

	const defaultMapProps = {
		center: {
			lat: 40.4167,
			lng: -3.70325,
		},
		zoom: 13,
	};

	const [initialData, setInitialData] = useState({
		name: "",
		description: "",
		address: "",
		fullAddress: "",
		number: "",
		street: "",
		city: "Madrid",
		district: "Madrid",
		community: "Comunidad de Madrid",
		country: "Spain",
		postalCode: "",
		phone: "",
		images: [],
		schedule: [],
		status: "ACTIVE",
		placeId: "",
		links: ["", "", "", "", "", "", ""],
		_geoloc: {},
		_tags: ["not_claimed"],
		isDataCorrect: false,
		madridProject: true
	});
	const [coordinates, setCoordinates] = useState(null);
	const [urlModal, setUrlModal] = useState(false);
	const [url, setUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [loadingCoordinates, setLoadingCoordinates] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);

	useEffect(() => {
		id && fetchData();
	}, []);

	const fetchData = async () => {
		firebase
			.firestore()
			.collection("stores")
			.doc(id)
			.get()
			.then((res) => {
				setInitialData(res.data());
				setCoordinates(res.data()._geoloc);
			});
	};

	const StoreSchema = Yup.object().shape({
		name: Yup.string().min(3, "El nombre del establecimiento debe tener más de 2 caracteres").max(80, "El nombre del establecimiento no puede superar los 50 caracteres").required("Pon un nombre del establecimiento"),
		// links: Yup.string().url("La URL introducida no es válida. Ejemplo: https://urlalestablecimiento.com"),
	});

	const imageDropped = (file, values) =>
		new Promise((resolve, reject) => {
			setLoadingImage(true);
			console.log(file);
			const fileRef = firebase.storage().ref().child(id ? `stores/${id}/${new Date().valueOf()}${file[0].name}` : `stores/images/${new Date().valueOf()}${file[0].name}`);
			fileRef
				.put(file[0])
				.then((res) => {
					firebase
						.storage()
						.ref(res.ref.fullPath)
						.getDownloadURL()
						.then((res) => resolve(res))
						.catch((err) => reject(err));
				})
				.catch((err) => reject(err));
		});

	const deleteStore = async () => {
		setLoading(true);
		firebase
			.firestore()
			.collection("stores")
			.doc(id)
			.delete()
			.then((res) => {
				setLoading(false);
				alert("El establecimiento se ha eliminado con éxito");
				// history.push("/stores");
				window.close()
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				alert("Ha sucedido un error al eliminar el establecimiento ", JSON.stringify(err));
			});
	};

	const submitForm = async (values) => {
		setLoading(true);
		console.log(values)
		id
			? firebase
				.firestore()
				.collection("stores")
				.doc(id)
				.update({ ...values, madridProject: true, owner: authUser.owner })
				.then((res) => {
					setLoading(false);
					alert("El establecimiento se ha actualizado con éxito");
					// history.push("/massstores");
					window.close()
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
					alert("Ha sucedido un error ", JSON.stringify(err));
				})
			: firebase
				.firestore()
				.collection("stores")
				.add({ ...values, madridProject: true, owner: authUser.owner })
				.then((res) => {
					setLoading(false);
					alert("El establecimiento se ha agregado con éxito");
					history.push("/massstores");
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
					alert("Ha sucedido un error ", JSON.stringify(err));
				});
	};

	const getCoordinates = async (address) => {
		setLoadingCoordinates(true);
		console.log(initialData.name + " " + initialData.fullAddress + " Madrid")
		axios
			.post("https://europe-west1-retailhub-6602d.cloudfunctions.net/locationData", {
				// .post("http://localhost:5001/retailhub-6602d/europe-west1/locationData", {
				method: "POST",
				body: JSON.stringify({ address: initialData.name + "-" + initialData.fullAddress + "-Madrid", id: id }),
			})
			.then((response) => {
				const info = response.data;
				console.log("Success:", info);
				if (info.error) {
					alert(info.error);
					setLoadingCoordinates(false);
				} else {

					const addressData = info?.address_components || [];
					const number = addressData?.find((el) => el.types.includes("street_number"))?.long_name?.replace("Nº", "") || "";
					const street = addressData?.find((el) => el.types.includes("route"))?.long_name || "";
					const city = addressData?.find((el) => el.types.includes("locality"))?.long_name || "";
					const postalCode = addressData?.find((el) => el.types.includes("postal_code"))?.long_name || "";

					const links = initialData.links;
					links[0] = info?.website || "";
					links[6] = info?.formatted_phone_number || "";

					setInitialData({
						...initialData,
						_geoloc: info.location,
						placeId: info.place_id || "",
						phone: info?.formatted_phone_number || "",
						name: info.name || "",
						schedule: info?.opening_hours?.weekday_text || [],
						links: links,
						images: info.photos || [],
						fullAddress: info.formatted_address || "",
						number: parseInt(number),
						street,
						city,
						postalCode,
					});
					setCoordinates(info.location);
					setLoadingCoordinates(false);
				}
			})
			.catch((error) => {
				setLoadingCoordinates(false);
				console.log(error);
				throw error;
			});
	};

	const onKeyDown = (keyEvent) => {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
		}
	};

	return (
		<Formik enableReinitialize={true} initialValues={initialData} validationSchema={StoreSchema} onSubmit={async (values) => submitForm(values)}>
			{({ values, errors, touched }) => (
				<Form onKeyDown={onKeyDown}>
					<Box flex="grow" background="#F6F6F6" pad="medium" gap="medium">
						<Box direction="row" justify="between">
							<Box direction="row" align="center" gap="small">
								<Box width="40px" height="40px" align="center" justify="center" background="white" round="7px" elevation="medium">
									<Button onClick={() => history.push("/massstores")} plain icon={<CaretPrevious size="20px" />} />
								</Box>
								<Heading margin="none" level="4">
									{id ? initialData.name : "Nuevo Establecimiento"}
								</Heading>
							</Box>
							<Box direction="row" gap="small">
								{id && !loading && (
									<Button onClick={() => deleteStore()} type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="status-critical">
										Eliminar
									</Button>
								)}
								{!loading ? (
									<Button type="submit" style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Guardar
									</Button>
								) : (
									<Loader height="35px" color="#170747" width="50px" type="ThreeDots" />
								)}
							</Box>
						</Box>

						{/* BLOQUE PRINCIPAL*/}

						<Box direction="row" gap="medium">
							<Box gap="medium" width="75%">
								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box>
										<label htmlFor="name">Nombre del Establecimiento</label>
										<Field className="basicInput" id="name" name="name" placeholder="Nombre del Establecimiento..." />
										{errors.name && touched.name ? (
											<Text size="16px" color="status-critical">
												{errors.name}
											</Text>
										) : null}
									</Box>
									<TextBox name="description" label="Descripción" />
									<Text size="12px">Esta descripción aparecerá en la ficha de tu comercio para los usuarios. Puedes hablar sobre ti o los productos o servicios que ofreces.</Text>
								</Box>

								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Información
											</Heading>
										</Box>
									</Box>
									<Box>
										<label htmlFor="name">Dirección del Establecimiento</label>
										<Box direction="row" gap="small">
											<Field className="basicInput" style={{ flex: 1 }} id="address" name="address" placeholder="Dirección..." />
											{!loadingCoordinates ? (
												<Button
													onClick={() => getCoordinates(values.address)}
													style={{ width: 160, color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 45, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
													primary
													color="brand">
													Auto Completar
												</Button>
											) : (
												<Box align="center" justify="center">
													<Loader height="15px" color="#170747" width="120px" type="ThreeDots" />
												</Box>
											)}
										</Box>
									</Box>
									<Box height="300px" width="100%">
										<GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyA7522FCbabm1HahH84jN8_GKK6uw4lJmE" }} defaultCenter={defaultMapProps.center} center={coordinates && [coordinates.lat, coordinates.lng]} defaultZoom={defaultMapProps.zoom}>
											{coordinates && (
												<CustomMarker
													style={{ backgroundImage: "url(https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png)", backgroundSize: "cover", backgroundRepeat: "no-repeat", height: 40, width: 40, marginTop: -40, marginLeft: -20 }}
													lat={coordinates.lat}
													lng={coordinates.lng}
												/>
											)}
										</GoogleMapReact>
									</Box>

									<Box direction="row" gap="small">
										<Field className="basicInput" style={{ flex: 4 }} id="street" name="street" placeholder="Calle..." />
										<Field className="basicInput" style={{ flex: 1 }} type="number" id="number" name="number" placeholder="Número..." />
									</Box>
									<Box direction="row" gap="small">
										<Field className="basicInput" style={{ flex: 1 }} id="city" name="city" placeholder="Ciudad..." />
										<Field className="basicInput" style={{ flex: 1 }} id="district" name="district" placeholder="Distrito..." />
										<Field className="basicInput" style={{ flex: 1 }} type="number" id="postalCode" name="postalCode" placeholder="Código Postal..." />
									</Box>
								</Box>

								<Box background="white" pad="medium" elevation="medium" gap="small" round="10px">
									<Box direction="row">
										<Box flex>
											<Heading margin="0px" level="4" color="#170747">
												Imágenes
											</Heading>
										</Box>
										<Box onClick={() => setUrlModal(true)}>
											<Text size="small" color="secondary" style={{ textDecoration: "underline" }}>
												Añadir desde una url
											</Text>
										</Box>
									</Box>

									<FieldArray name="images">
										{({ insert, remove, push }) => (
											<>
												<Box gap="small">
													<Dropzone
														accept="image/*"
														maxFiles={10}
														disabled={values.images.length >= 10}
														maxSize={2242880}
														onDropAccepted={(ev) =>
															imageDropped(ev).then((res) => {
																push(res);
																setLoadingImage(false);
																console.log(res);
															})
														}>
														{({ acceptedFiles, getRootProps, getInputProps }) => (
															<Box round="5px" border={{ size: "2px", style: "dashed", color: "gainsboro" }} align="center" justify="center" height={{ min: "150px" }} {...getRootProps()}>
																<input {...getInputProps()} />
																<Text size="small" color="secondary">
																	{loadingImage ? "Cargando la Imagen... " : "Haz Click o Arrastra imagenes aquí. (2MB Max por imagen"}
																</Text>
															</Box>
														)}
													</Dropzone>
													<Box direction="row" gap="small" overflow="scroll">
														{values?.images?.length > 0 &&
															values.images.map((image, index) => (
																<Stack key={index} anchor="top-right">
																	<Box round="5px" overflow="hidden" background="gainsboro" width="100px" height="100px">
																		<Image fit="cover" src={image} />
																	</Box>
																	<Button style={{ marginTop: 0, borderRadius: 5, padding: 7, marginRight: 0 }} primary color="status-critical" onClick={() => remove(index)} icon={<Trash color="white" size="15px" />} />
																</Stack>
															))}
													</Box>
												</Box>
												{urlModal && (
													<Layer>
														<Box pad="medium" gap="medium" width="medium">
															<Text>Añadir Imagen desde una URL</Text>
															<Box>
																<TextInput value={url} onChange={(ev) => setUrl(ev.target.value)} placeholder="URL de la imagen" />
																{!url.includes("http") && (
																	<Text style={{ fontSize: "10px" }} color="status-critical">
																		La url tiene que tener este formato: https://urldelaimagen.com
																	</Text>
																)}
															</Box>
															<Box direction="row" justify="between">
																<Button
																	onClick={() => {
																		setUrlModal(false);
																		setUrl("");
																	}}
																	style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
																	primary
																	color="gainsboro">
																	Cancelar
																</Button>
																<Button
																	disabled={url === "" && StoreSchema.isValid({ link: url }).then((res) => console.log(res))}
																	onClick={() => {
																		push(url);
																		setUrlModal(false);
																		setUrl("");
																	}}
																	style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
																	primary
																	color="brand">
																	Añadir
																</Button>
															</Box>
														</Box>
													</Layer>
												)}
											</>
										)}
									</FieldArray>
								</Box>
							</Box>

							<Box gap="medium" width="25%">
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<SelectBox
										placeholder="Inactivo"
										name="status"
										label="Status"
										labelKey="label"
										valueKey={{ key: "value", reduce: true }}
										options={[
											{ value: "ACTIVE", label: "Abierto" },
											{ value: "HOLIDAYS", label: "Vacaciones" },
											{ value: "CLOSED", label: "Cerrado" },
										]}
									/>
									<Text size="12px">El estado indicará si el establecimiento esta abierto, cerrado, de vacaiones...</Text>
								</Box>
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<Button onClick={ev => {
										ev.preventDefault();
										navigator.clipboard.writeText(initialData.images.join(", \r\n"))
									}} style={{ color: "white", fontWeight: "600", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }} primary color="brand">
										Copiar Fotos
									</Button>
								</Box>
								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									{/* <CheckBox
										name="isDataCorrect"
										id="isDataCorrect"
										label="Los Datos del AYDM son correctos"
									/> */}
									<label>
										<Field type="checkbox" name="isDataCorrect" />
										Los Datos del AYDM son correctos
									</label>
								</Box>
								<Box background="white" gap="small" border={{ side: "top", color: "brand", size: "7px" }} pad="medium" elevation="medium" round="10px">
									<Heading margin="0px" level="4" color="#170747">
										Horarios
									</Heading>
									<Text size="12px">Introduce los horarios de tu comercio.</Text>
									<FieldArray name="schedule">
										{({ insert, remove, push }) => (
											<Box gap="medium">
												<Box gap="small">
													{values?.schedule?.length > 0 ? (
														values.schedule.map((variant, i) => (
															<Box key={i} direction="row">
																<Box style={{ flex: 1 }}>
																	<Field className="basicInput" id={`schedule.${i}`} name={`schedule.${i}`} placeholder="Horas..." />
																</Box>
																<Button hoverIndicator onClick={() => remove(i)} icon={<Trash />} />
															</Box>
														))
													) : (
														<Box height="150px" background="#f8f8f8" round="small" align="center" justify="center">
															<Text>Sin Horarios</Text>
														</Box>
													)}
												</Box>

												<Box align="start">
													<Box border={{ size: "2px", color: "secondary" }} round="5px">
														<Button
															onClick={() => push("")}
															style={{ fontWeight: "600", borderWidth: 2, borderColor: "#170747", textAlign: "center", paddingLeft: 15, paddingRight: 15, height: 35, borderRadius: 5, alignItems: "center", justifyContent: "center" }}
															color="#170747">
															Añadir Horas
														</Button>
													</Box>
												</Box>
											</Box>
										)}
									</FieldArray>
								</Box>

								<Box background="white" gap="small" pad="medium" elevation="medium" round="10px">
									<Heading margin="0px" level="4" color="#170747">
										Enlaces
									</Heading>
									<Text size="12px">Enlaces a la web del comercio y redes sociales.</Text>
									<FieldArray name="schedule">
										{({ insert, remove, push }) => (
											<Box gap="small">
												<Box>
													<label htmlFor="links.0">Página Web</label>
													<Field className="basicInput" id="links.0" name="links.0" placeholder="https://paginaweb.com" />
												</Box>
												<Box>
													<label htmlFor="links.1">Instagram</label>
													<Field className="basicInput" id="links.1" name="links.1" placeholder="https://instagram.com/marca" />
												</Box>
												<Box>
													<label htmlFor="links.2">Facebook</label>
													<Field className="basicInput" id="links.2" name="links.2" placeholder="https://facebook.com/marca" />
												</Box>
												<Box>
													<label htmlFor="links.3">Twitter</label>
													<Field className="basicInput" id="links.3" name="links.3" placeholder="https://twitter.com/marca" />
												</Box>
												<Box>
													<label htmlFor="links.4">Youtube</label>
													<Field className="basicInput" id="links.4" name="links.4" placeholder="https://youtube.com/marca" />
												</Box>
												<Box>
													<label htmlFor="links.5">Email</label>
													<Field className="basicInput" id="links.5" name="links.5" placeholder="email@comercio.com" />
												</Box>
												<Box>
													<label htmlFor="links.6">Teléfono</label>
													<Field className="basicInput" id="links.6" name="links.6" placeholder="Teléfono..." />
												</Box>
											</Box>
										)}
									</FieldArray>
								</Box>
							</Box>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default NewProductsForm;

const CustomMarker = ({ lat, lng }) => <div lat={lat} lng={lng} style={{ backgroundImage: "url(https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png)", backgroundSize: "cover", backgroundRepeat: "no-repeat", height: 40, width: 40, marginTop: -40, marginLeft: -20 }} />;
